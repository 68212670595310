import { useCallback, useMemo } from "react";
import { useAccount } from '../state/account';

import { useContractWithProviderAndFunctions } from "./contracts/contracts";
import { useIdoNetworkFactoryAddress } from './idos';
import { useNativeCoin } from './token-utils';

export const useIdoFactoryContract = (networkId, baseAsset, type, provider = window.web3) => {
    const [address, abi] = useIdoNetworkFactoryAddress(networkId, baseAsset, type);
    const contract = useContractWithProviderAndFunctions(address, abi, provider);
    return contract;
};

const CREATE_METHODS = {
    "presale": {
        "native": "buildNativeWhitelistStakingIDO",
        "erc20": "buildERC20WhitelistStakingIDO"
    },
    "fairlaunch": {
        "native": "buildNativeWhitelistStakingFL",
        "erc20": "buildERC20WhitelistStakingFL"
    }
}

export const useIdoFactoryActions = (networkId, baseAsset, type = "presale") => {
    const idoContract = useIdoFactoryContract(networkId, baseAsset, type, window.web3);
    const account = useAccount();
    const nativeCoin = useNativeCoin(networkId)

    const resultHandler = useCallback(async result => {
        const receipt = await result;
        const idoAddress = receipt.events["IdoCreated"].returnValues.newIdoAddress;
        return { idoAddress, txhash: receipt.transactionHash };
    }, []);

    const handles = useMemo(() => {
        if (!nativeCoin || !idoContract || !baseAsset || !type || !buildHandles) {
            return;
        }
        const _type = baseAsset === nativeCoin.symbol ? "native" : "erc20";
        return buildHandles(CREATE_METHODS[type][_type]);
    }, [idoContract, baseAsset, type, buildHandles, nativeCoin])

    if (handles && idoContract) {
        return handles(account, idoContract, resultHandler);
    } else {
        return { create: null };
    }
};

export const buildHandles = (method) => (account, idoContract, handler) => {
    const create = async (...params) => {
        const gasPrice = await window.web3.eth.getGasPrice();
        const tax = await idoContract.methods.tax().call();

        params = params.filter(item => item !== undefined);
        let args = {
            from: account,
            gasPrice: gasPrice,
            value: tax
        }

        await idoContract.methods[method](...params).estimateGas(args);
        const result = idoContract.methods[method](...params).send(args);
        return result.then(handler);
    }

    return { create };
}

