import React, { useContext, useEffect, useState } from "react";
import "./new-ido-preview.scss";
import upperLogo from "./../../../images/upper-logo.png";
import f2c from "./../../../images/f2c-hpad.png";
import refundimg from "./../../../images/refund-hpad.png";
import { networks } from "../../../web3/web3";
import NetworkContext from "../../../context/network-context";
import { Link } from "gatsby";
import Transak from "components/transak";
import SaleDropDown from "./SaleDropDown";
import { useIdoActions } from "web3/idos";
import { fToken } from "utils/formatNumber";
import Countdown from "react-countdown";
import { useIdo as useFairLaunch } from "web3/fair-launch";
import { useIdo } from "web3/idos";

function IdoCardStatus({ idoCardStatus }) {
  return (
    <>
      {
        {
          0: (
            <>
              <div className="upper-live">
                <span className="upper-upcoming-dot"></span>
                Upcoming
              </div>
            </>
          ),
          1: (
            <>
              <div className="upper-live">
                <span className="upper-live-dot"></span>
                Live
              </div>
            </>
          ),
          2: (
            <>
              <div className="upper-live">
                <span className="upper-filled-dot"></span>
                Filled
              </div>
            </>
          ),
          3: (
            <>
              <div className="upper-live">
                <span className="upper-failed-dot"></span>
                Failed
              </div>
            </>
          ),
          default: null
        }[idoCardStatus]
      }
    </>
  );
}

function HpadIdo({ uncomido }) {
  const [transk, setTransk] = useState(false);
  const { connected, account, isCorrectNetwork, pendingTransaction, executeTransaction } = useContext(NetworkContext);
  const { emergencyWithdraw, refund } = useIdoActions(uncomido.presaleContractAddress, account);
  const [claimed, setClaimed] = useState(false);
  const [progress, setProgress] = useState(0);
  const [idoBCData, setIdoBCData] = useState();
  const fetchIdo = useIdo();
  const fetchFairLaunch = useFairLaunch();

  useEffect(() => {
    const fetchBCFairData = async () => {
      let data = await fetchFairLaunch(uncomido.presaleContractAddress);
      console.log(data);
      setIdoBCData(data);
    };
    const fetchBCData = async () => {
      let data = await fetchIdo(uncomido.presaleContractAddress);
      console.log(data);
      setIdoBCData(data);
    };
    if (uncomido.type === "fairlaunch") {
      fetchBCFairData().catch(console.error);
    } else {
      fetchBCData().catch(console.error);
    }
  }, [uncomido]);

  useEffect(() => {
    if (!idoBCData) return;
    if (uncomido.type === "fairlaunch") {
      setProgress(Math.min(100, (idoBCData.capital * 100) / idoBCData.softCap));
    } else {
      setProgress(Math.min(100, (idoBCData.capital * 100) / idoBCData.hardCap));
    }
  }, [idoBCData]);

  const handleTransk = () => {
    setTransk(true);

    setTimeout(() => {
      setTransk(false);
    }, 100);
  };

  const handleRefund = async () => {
    const tx = async () => emergencyWithdraw();

    await executeTransaction({
      message: "Emergency Withdraw",
      tx
    }).then(() => {
      setClaimed(true);
    });
  };

  return (
    <>
      {transk && <Transak />}
      <div className="presale-preview-component ">
        <div className="upper-part">
          <div className="upper-heading align-items-center">
            <h5 className="mb-0 popin-600-20 d-flex just justify-content-between">
              {uncomido.type === "presale" ? <>Classic Presale</> : <> {idoBCData?.isFairlaunch ? <>Liquidity Raise</>:<>Fair Launch</>}</>}
            </h5>
            <div>{idoBCData ? <IdoCardStatus idoCardStatus={idoBCData.status} /> : null}</div>
          </div>
          <div className="upper-details">
            <div className="upper-logo-name">
              <img
                loading="lazy"
                src={uncomido.info.imagePath ? uncomido.info.imagePath : upperLogo}
                style={{ width: 80, height: 80, borderRadius: 40, objectFit: "cover" }}
              ></img>
              <div className="upper-name">
                <div className="popin-600-18 gold-text">{uncomido.name}</div>
                <div className="popin-600-16 grey3-text">{uncomido.ticker}</div>
              </div>
            </div>
            <div>
              <div className="popin-600-11 d-flex justify-content-end">
                {uncomido.audit == true && <span className="border-ido upper-horizontal-menu">Audit</span>}
                {uncomido.kyc == true && <span className="border-ido upper-horizontal-menu">KYC</span>}
                <span className="border-ido upper-horizontal-menu">
                  <span>
                    <img
                      loading="lazy"
                      src={`/idoicon/woBG/${networks[uncomido.chainId]?.shortName}.svg`}
                      style={{ width: 15, height: 15, marginRight: 5 }}
                    ></img>
                  </span>
                  {networks[uncomido.chainId]?.shortName}
                </span>
              </div>
              <div className="upper-progress-bar">
                <div className="upper-progress" style={{ width: progress + "%" }}></div>
              </div>
              <div className="upper-amoint-progress popin-500-12 d-flex align-items-center justify-content-between">
                <span className="grey1-text">Raised</span>
                <div className="d-flex">
                  {idoBCData ? (
                    <>
                      <span>{fToken(+idoBCData.capital)}</span>
                    </>
                  ) : null}
                  <span>/</span>
                  {uncomido.type === "presale" ? (
                    <>
                      <span>{uncomido.details.hardCap}</span>
                      <span>{uncomido.details.baseSymbol}</span>
                    </>
                  ) : (
                    <>
                      <span>{uncomido.fairLaunchDetails.softCap}</span>
                      <span>{uncomido.fairLaunchDetails.baseSymbol}</span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mid-border"></div>
        <div className="lower-part">
          <div role="button" onClick={() => handleTransk()}>
            <img loading="lazy" src={f2c}></img>
            <div>F2C</div>
          </div>
          <div className="lower-sales-div">
            <SaleDropDown ido={idoBCData} uncomido={uncomido} />
          </div>
          <div
            role="button"
            onClick={handleRefund}
            disabled={!connected || !isCorrectNetwork || !!pendingTransaction || claimed}
          >
            <img loading="lazy" src={refundimg}></img>
            <div>Refund</div>
          </div>
          <div>
            <Link to={`/hpad/${uncomido.presaleContractAddress}`}>
              <button className="lower-view-button">View Sales</button>
            </Link>
            <div>
              {idoBCData ? (
                <>
                  {(() => {
                    switch (idoBCData?.status) {
                      case 0:
                        return (
                          <>
                            <div className="lower-timer-outer">
                              Upcoming:
                              <Countdown key={idoBCData.startTime} date={idoBCData.startTime}></Countdown>
                            </div>
                          </>
                        );
                      case 1:
                        return (
                          <>
                            <div className="lower-timer-outer">
                              Ends In:
                              <Countdown key={idoBCData.endTime} date={idoBCData.endTime}></Countdown>
                            </div>
                          </>
                        );
                      case 2:
                        return (
                          <>
                            <div className="lower-timer-outer">Filled</div>
                          </>
                        );
                      case 3:
                        return (
                          <>
                            <div className="lower-timer-outer">Ended</div>
                          </>
                        );
                      default:
                        return null;
                    }
                  })()}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HpadIdo;
