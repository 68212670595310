import React, { useContext } from 'react';
import NetworkContext from '../../../context/network-context';
import { useCurrentIdo, useUserBalanaceAmount, useUserInvestment } from '../../../state/idos';
import { useIdoActions } from '../../../web3/fair-launch';
import Claim2 from "./../common/ido-claim2";

function IdoClaim({ address }) {
    const { account } = useContext(NetworkContext);
    const { ido } = useCurrentIdo(address);
    const { investedAmount } = useUserInvestment(address);
    const { userBalance } = useUserBalanaceAmount(address);
    const { claim, refund } = useIdoActions(address, account);
    
    return <Claim2  ido={ido} investedAmount={investedAmount} userBalance={userBalance} claim={claim} refund={refund}> </Claim2>
}

export default IdoClaim;
