import classNames from "classnames";
import React, { useCallback, useContext, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { Button, Card, Col, Form, InputGroup, OverlayTrigger, Row, Tooltip } from "react-bootstrap";

import NetworkContext from "../../../context/network-context";
import { computeTrade, getDefaultRouter } from "../../../utils/utils";
import { getIdoExchangePairToken } from "../../../web3/token-utils";
import { fToken } from "../../../utils/formatNumber";
import AsyncItem from "../../misc/AsyncItem";

function FairLaunchIdoConfirm({
  mode,
  action,
  quoteValue,
  baseValue,
  base,
  quoteSymbol,
  price,
  idoBaseValue,
  quoteTokenIcon,
  ido,
  uncomido,
  relativeTokenPrice,
  idoBaseCoin
}) {
  const { pendingTransaction } = useContext(NetworkContext);

  const [minAmount, setMinAmount] = useState(0);
  const [minReceived, setMinReceived] = useState(0);
  const [intialShares, setInitialShares] = useState(0);

  const [rate, setRate] = useState(price);
  const [pair, setPair] = useState(price);

  const [slippage, setSlippage] = useState();
  const [slippageAuto, setSlippageAuto] = useState(true);

  const [warning, setWarning] = useState(null);
  const [calculatingSlippage, setCalculatingSlippage] = useState(true);

  const calclateAutoSlippage = useCallback(async () => {
    if (base.symbol.toLowerCase() === uncomido.details.baseSymbol.toLowerCase()) {
      return 0;
    }
    setCalculatingSlippage(true);

    const idoBase = getIdoExchangePairToken(uncomido.details.baseSymbol, uncomido.chainId);
    const router = getDefaultRouter(uncomido.chainId);
    const trade = await computeTrade(+baseValue, [base, idoBase], router, false);
    setCalculatingSlippage(false);

    return +(+trade.priceImpact.toFixed(2) + 0.3).toFixed(2);
  }, [baseValue, base, ido]);

  const checkValid = useCallback(
    slippage => {
      if (base.symbol.toLowerCase() !== uncomido.details.baseSymbol.toLowerCase()) {
        if (slippage > 5) {
          setWarning("Warning! High slippage can lead to an extremely unfavorable exchange price.");
          return;
        }
        if (slippage < 0.2) {
          setWarning("Warning! Transaction may fail.");
          return;
        }
      }
      setWarning(null);
    },
    [base, ido]
  );

  const updateSlippage = useCallback(
    async slippage => {
      if (slippage === "auto") {
        slippage = await calclateAutoSlippage();
        setSlippageAuto(true);
      } else {
        setSlippageAuto(false);
      }

      if (slippage > 50) {
        slippage = 50;
        setWarning("Maximum slippage is 50.");
      }

      setSlippage(slippage);
      checkValid(slippage);
    },
    [setSlippage, checkValid, calclateAutoSlippage]
  );

  useEffect(() => {
    updateSlippage("auto");
  }, [updateSlippage]);

  useEffect(() => {
    let _minBaseAmount = +baseValue;
    let _minReceived = quoteValue;
    let _initialShares = quoteValue;

    if (base.symbol !== uncomido.details.baseSymbol) {
      _minBaseAmount = idoBaseValue - idoBaseValue * ((+slippage + 1) / 100);

      let _price = price;
      if (ido.type === "fairlaunch") {
        const totalCapital = ido.capital + Number(idoBaseValue);
        _price = ido.amountForSale / totalCapital;
      }

      _minReceived = ido.type === "fairlaunch" && ido.capital === 0 ? quoteValue : _minBaseAmount * _price;
    }

    setInitialShares(_initialShares);
    setMinAmount(_minBaseAmount);
    setMinReceived(_minReceived);
  }, [slippage, quoteValue, base, baseValue, price,uncomido, ido, idoBaseValue]);

  useEffect(() => {
    if (!ido) {
      return;
    }
    if (ido.inversedRate) {
      setRate(1 / +price);
      setPair(`${quoteSymbol.toUpperCase()}/${uncomido.details.baseSymbol.toUpperCase()}`);
    } else {
      setRate(+price);
      setPair(`${uncomido.details.baseSymbol.toUpperCase()}/${quoteSymbol.toUpperCase()}`);
    }
  }, [price, ido,uncomido]);

  return (
    <div className="staking-input-component">
      <Card>
        <Card.Body>
          <Row style={{ marginBottom: "1rem" }}>
            <Col md={12} className=" text-center ">
              <h3 className="text-white ">Confirm</h3>
            </Col>
          </Row>
          <Row className="exchange-confirm-summary">
            {mode === "hop" && (
              <Col md={12} className="mb-4 d-flex justify-content-between">
                <div className="exchange-group">
                  <img src={`${base.image}`} style={{ borderRadius: "50%" }} alt={"bnb"} width={40} height={40} />
                  <div className="exchange-group-input">
                    <small className="exchange-confirm-value-label">Enter Amount</small>
                    <span className="exchange-confirm-value-item ">{fToken(baseValue)}</span>
                  </div>
                </div>
                <span className="exchange-confirm-currency "> {base.symbol.toUpperCase()} </span>
              </Col>
            )}

            <Col md={12} className="mb-4 d-flex justify-content-between">
              <div className="exchange-group">
                <img src={idoBaseCoin.image} alt={"ido base"} style={{ borderRadius: "50%" }} width={40} height={40} />
                <div className="exchange-group-input">
                  <small className="exchange-confirm-value-label"> Deposit Value </small>
                  <span className="exchange-confirm-value-item ">{fToken(idoBaseValue)}</span>
                </div>
              </div>
              <span className="exchange-confirm-currency"> {uncomido.details.baseSymbol.toUpperCase()} </span>
            </Col>

            <Col md={12} className="mb-4 d-flex justify-content-between">
              <div className="exchange-group">
                <img src={quoteTokenIcon} style={{ borderRadius: "50%" }} alt={"solidus"} width={40} height={40} />
                <div className="exchange-group-input">
                  <small className="exchange-confirm-value-label">Initial Shares</small>
                  <span className="exchange-confirm-value-item ">
                    {fToken(intialShares)}
                    <OverlayTrigger
                      overlay={
                        <Tooltip id={`tooltip-info-share`}>
                          <strong>
                            The amount of {quoteSymbol.toUpperCase()} that will be allocated to you. This value will
                            change as more contibutors enter or exit the fairlaunch.{" "}
                          </strong>
                        </Tooltip>
                      }
                    >
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-question-circle info-icon"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
                        </svg>
                      </span>
                    </OverlayTrigger>
                  </span>
                </div>
              </div>
              <span className="exchange-confirm-currency">{quoteSymbol.toUpperCase()} </span>
            </Col>
          </Row>

          {mode === "hop" && (
            <Row className="mb-5">
              <Col md={12} className="d-flex align-items-center mb-3">
                <small className="exchange-confirm-value-label">Slippage (50% Maximum)</small>
              </Col>

              <Col md={12} className="text-end d-flex slippage-wrapper">
                <Button
                  className={classNames("slippage-btn", { active: slippageAuto === true })}
                  onClick={() => updateSlippage("auto")}
                >
                  Auto
                </Button>
                <Button
                  className={classNames("slippage-btn", { active: slippage === 0.5 })}
                  onClick={() => updateSlippage(0.5)}
                >
                  0.5 %
                </Button>
                <Button
                  className={classNames("slippage-btn", { active: slippage === 1 })}
                  onClick={() => updateSlippage(1)}
                >
                  1 %
                </Button>

                <InputGroup className="slippage-input">
                  <AsyncItem loading={calculatingSlippage}>
                    <Form.Control
                      className="exchange-confirm-input ms-auto text-end"
                      type="text"
                      value={slippage}
                      autoComplete="off"
                      inputMode="decimal"
                      onChange={e => updateSlippage(e.target.value)}
                    />
                  </AsyncItem>

                  <InputGroup.Text>%</InputGroup.Text>
                </InputGroup>
              </Col>
            </Row>
          )}

          <Row className="mb-2">
            <Col md={4} className="d-flex align-items-center">
              <small className="exchange-confirm-value-label">Current Rate</small>
            </Col>

            <Col md={8} className="text-end">
              <small className="exchange-confirm-value-label ">
                {ido?.capital > 0 ? (
                  <>
                    {fToken(+rate)} {pair}
                  </>
                ) : (
                  "Not available"
                )}
              </small>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="d-flex align-items-center">
              <small className="exchange-confirm-value-label">Minimum Recevied</small>
            </Col>

            <Col md={6} className="text-end">
              <small className="exchange-confirm-value-label mt-0">
                {fToken(+minReceived)} {quoteSymbol.toUpperCase()}
                <OverlayTrigger
                  overlay={
                    <Tooltip id={`tooltip-Whatsapp`}>
                      <strong>All taxes are included</strong>
                    </Tooltip>
                  }
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-question-circle info-icon"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
                    </svg>
                  </span>
                </OverlayTrigger>
              </small>
            </Col>
          </Row>

          <Button
            disabled={(base.symbol !== uncomido.details.baseSymbol && (slippage > 50 || !slippage)) || pendingTransaction}
            onClick={() => {
              action(Number(minAmount).toFixed(8));
            }}
            className="mt-5 w-100"
          >
            Confirm
          </Button>
          {base.symbol !== ido.baseSymbol && warning && (
            <p className="text-center text-warning mt-2 me-auto ms-auto">{warning}</p>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}

export default FairLaunchIdoConfirm;
