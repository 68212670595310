import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import NetworkContext from "../../../context/network-context";

function IdoClaim({ ido, investedAmount, userBalance, claim, refund }) {
  const { connected, isCorrectNetwork, pendingTransaction, executeTransaction } = useContext(NetworkContext);
  const [claimed, setClaimed] = useState(false);

  const handleClaim = async () => {
    const tx = async () => claim();

    await executeTransaction({
      message: "Claiming Tokens",
      tx
    }).then(() => {
      setClaimed(true);
    });
  };

  const handleRefund = async () => {
    const tx = async () => refund();

    await executeTransaction({
      message: "Refunding contribution",
      tx
    }).then(() => {
      setClaimed(true);
    });
  };

  useEffect(() => {
    const claimed = userBalance === 0;
    setClaimed(claimed);
  }, [investedAmount, userBalance]);

  return (
    <>
      <h3 className="mb-0 text-white text-center text-lg-start d-flex align-items-center justify-content-center popin-600-24">
        {ido.type == "fairlaunch" ? <>{ido.isFairlaunch === true ? (<>Liquidity Raise</>) : (<>Fairlaunch</>) }</> : <>Presale</>} Ended
      </h3>
      
        {ido && ido.status === 2 && (
          <>
            {!claimed ? (
              <>
                <p className="text-center mb-4">
                  This {ido.type == "fairlaunch" ? <>{ido.isFairlaunch === true ? (<>Liquidity Raise</>) : (<>Fairlaunch</>) }</> : <>presale</>}&nbsp;is over.&nbsp; <br />
                  {userBalance === 0 ? (
                    <>You will be able to claim your tokens once the owner finalizes this funding phase.</>
                  ) : (
                    <>You can claim {userBalance} tokens now.</>
                  )}
                </p>
              </>
            ) : (
              <p className="text-center mb-4">
                This {ido.type == "fairlaunch" ? <>{ido.isFairlaunch === true ? (<>Liquidity Raise</>) : (<>Fairlaunch</>) }</> : <>presale</>}&nbsp;is over. There is nothing left to
                claim.
              </p>
            )}
            <Button
              disabled={!connected || !isCorrectNetwork || !!pendingTransaction || !ido.canClaim || claimed}
              onClick={handleClaim}
              className="mt-5 mb-2 w-100"
            >
              Claim
            </Button>
          </>
        )}
        {ido && (ido.status === 3 || ido.cancelled) && (
          <>
            <p className="text-center mb-4">
              This IDO has did not raise the minumum amount of tokens in order to get validated.
            </p>
            <Button
              onClick={handleRefund}
              disabled={!connected || !isCorrectNetwork || !!pendingTransaction || claimed}
              className="mt-5 mb-2 w-100"
            >
              Refund
            </Button>
          </>
        )}
    </>
  );
}

export default IdoClaim;
