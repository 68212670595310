import { parseEther, parseUnits } from "ethers/lib/utils"
import { navigate } from "gatsby"
import Ido from "hpay/contracts/Ido.json"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { toast } from "react-toastify"
import { createIdo } from "../api/ido"
import Terms from "../components/create/Terms"
import NetworkSetter from "../components/network/networksetter"
import NetworkContext from "../context/network-context"
import { ZERO_ADDRESS } from "../utils/utils"
import { ContractFactoryHof } from "../web3/contracts/contract.factory"
import { useIdoFactoryActions } from "../web3/idos-create"
import { getExplorerLink, useNativeCoin } from "../web3/token-utils"
import { getProvider } from "../web3/web3"
import SelectToken from "../components/create/SelectToken"
import Summary from"../components/create/Summary"
import Information from "../components/create/Information"
import Tokenomics from "../components/create/Tokenomics"

const form = {
  termsandconditions: {
    branch: "presale",
  },
  summary: {
    decimals: 0,
    supply: 0,
    balance: 0,
    token: null,
    symbol: null,
    name: null,
  },
  token: {
    presale_creator: null,
    tokenAddress: null,
  },
  tokenomics: {
    amount_for_sale: null,
    tokens_for_presale: null,
    token_symbol: null,
    bal_range: 50,
    hard_cap: null,
    soft_cap: null,
    presale_rate: null,
    percent_towards_liquidity: 100,
    adjust_listing_rate: 10,
    min_contribution: null,
    max_contribution: null,
    start_date: new Date(Date.now() + 1000 * 60 * 60 * 24),
    end_date: new Date(Date.now() + 2000 * 60 * 60 * 24),
    ito_liquidity: 30,
    whitelist: "YES",
    base_symbol: "BUSD",
    liquidity_provider: null,
    launch_price: null,
  },
  information: {
    file: null,
    project_description: null,
    telegram: null,
    twitter: null,
    instagram: null,
    facebook: null,
    github: null,
    discord: null,
    linktree: null,
    youtube: null,
    tiktok: null,
    reddit: null,
    linkedin: null,
    medium: null,
    website: null,
    userexplorer: null,
    whitepaper: null,
    adsvideo: null,
    auditurl: null,
    kycurl: null,
  },
}
    // imagePath: "https://ipfs.io/ipfs/QmbDiRU8iGsA1ZzCcNHzU33dUu7gh7Cz7ikK8DP9PvMv45",
const CreateContainer = () => {
  const [network, setNetwork] = useState()

  const { account, executeTransaction } = useContext(NetworkContext)
  const [tab, setTab] = useState(1)
  const [isNextEnabled, setIsNextEnabled] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
  })
  const [formData, setFormData] = useState(form)
  const [baseTokens, setBaseTokens] = useState()
  const [isFairLaunch, setIsFairLaunch] = useState(false)

  const nativeCoin = useNativeCoin(network?.chainId)
  const { create } = useIdoFactoryActions(
    network?.chainId,
    formData?.tokenomics?.base_symbol,
    formData?.termsandconditions?.branch
  )

  const noToFairLaunch = () => {
    setIsFairLaunch(false)
  }

  const yesToFairLaunch = () => {
    setIsFairLaunch(true)
  }

  useEffect(() => {
    if (network) {
      const _tokens = [network?.nativeToken]
      if (network?.usdToken) {
        _tokens.push(network?.usdToken)
      }
      setBaseTokens(_tokens)
      setFormData({
        ...formData,
        tokenomics: {
          ...formData.tokenomics,
          base_symbol: network?.nativeToken?.symbol,
        },
      })
    }
  }, [setBaseTokens, network])

  useEffect(() => {
    setFormData({
      ...formData,
      token: { ...formData.token, presale_creator: account },
    })
  }, [account])

  const createPresale = useCallback(
    (_formData, baseToken) => {
      return create(
        {
          rate: parseUnits(
            _formData.tokenomics.presale_rate,
            +_formData.summary.decimals
          ), //rate,
          minBuy: parseEther(_formData.tokenomics.min_contribution), //min buy
          maxBuy: parseEther(_formData.tokenomics.max_contribution), // max buy
          hardCap: parseEther(_formData.tokenomics.hard_cap), // HardCap
          softCap: parseEther(_formData.tokenomics.soft_cap), // Soft Cap
          startTime: (
            Date.parse(_formData.tokenomics.start_date) / 1000
          ).toFixed(), // start date
          endTime: (Date.parse(_formData.tokenomics.end_date) / 1000).toFixed(), // end date
          emergencyTax: 0, //emergency tax fee);
          serviceFee: 0, //service fee,
          serviceFeeAddress: ZERO_ADDRESS,
        },
        {
          lockTime: _formData.tokenomics.ito_liquidity,
          liquidityAmount: _formData.tokenomics.percent_towards_liquidity,
          launchPrice: parseUnits(
            _formData.tokenomics.launch_price.toFixed(
              +_formData.summary.decimals
            ),
            +_formData.summary.decimals
          ),
          liquidityHost: _formData.tokenomics.liquidity_provider,
        },
        _formData.token.tokenAddress,
        baseToken
      )
    },
    [create]
  )

  const createFairlaunch = useCallback(
    (_formData, baseToken) => {
      return create(
        {
          amountForSale: parseUnits(
            "" + _formData.tokenomics.amount_for_sale,
            +_formData.summary.decimals
          ), //amount for sale
          minBuy: parseEther(_formData.tokenomics.min_contribution), //min buy
          maxBuy: parseEther(_formData.tokenomics.max_contribution), // max buy
          softCap: parseEther(_formData.tokenomics.soft_cap), // Soft Cap
          startTime: (
            Date.parse(_formData.tokenomics.start_date) / 1000
          ).toFixed(), // start date
          endTime: (Date.parse(_formData.tokenomics.end_date) / 1000).toFixed(), // end date
          emergencyTax: 0, //emergency tax fee);
          serviceFee: 0, //service fee,
          serviceFeeAddress: ZERO_ADDRESS,
        },
        {
          lockTime: _formData.tokenomics.ito_liquidity,
          liquidityAmount: _formData.tokenomics.percent_towards_liquidity,
          liquidityHost: _formData.tokenomics.liquidity_provider,
        },
        _formData.token.tokenAddress,
        baseToken
      )
    },
    [create]
  )

  const setNextEnabled = (
    stepIndex,
    nextEnabled,
    stepTitle = null,
    value = {}
  ) => {
    setIsNextEnabled({ ...isNextEnabled, [stepIndex]: nextEnabled })
    setFormData({
      ...formData,
      [stepTitle]: { ...formData[stepTitle], ...value },
    })
  }

  const setBaseSymbolAndRate = (baseSymbol, rate) => {
    setFormData({
      ...formData,
      tokenomics: {
        ...formData.tokenomics,
        base_symbol: baseSymbol,
        presale_rate: "" + rate,
      },
    })
  }

  function changeliquidityprovider(newValue) {
    setFormData(newValue)
  }

  const submit = async () => {
    if (tab === 4 && isNextEnabled[4]) {
      try {
        const tx = async () => {
          let baseToken = baseTokens.find(
            item => item.symbol === formData.tokenomics.base_symbol
          )
          baseToken =
            formData.tokenomics.base_symbol !== nativeCoin.symbol
              ? baseToken.address
              : undefined

          const createMethod =
            formData.termsandconditions.branch === "presale"
              ? createPresale
              : createFairlaunch
          return createMethod(formData, baseToken).then(
            async ({ idoAddress, txhash }) => {
              const idoContract = await ContractFactoryHof(
                getProvider(network?.chainId)
              ).create(Ido.abi, idoAddress)
              const totalSupply = await idoContract.methods.totalSupply().call()
              let body = buildRequestBody(account, totalSupply)
              body.presaleContractAddress = idoAddress
              body.presaleTransactionHash = txhash
              await createIdo(body, formData.information.file)
              toast.success("Success")
              setFormData(form)
              navigate(`/hpad/${idoAddress}`)
            }
          )
        }

        await executeTransaction({
          message: "Creating Presale",
          tx,
          throwErr: true,
        },

        )
      } catch (error) {
        console.log("error ========== ", error)
            toast.error("Something goes wrong here, Try again")
      }
    }

    function buildRequestBody(owner, totalSupply) {
      const body = {
        enabled: true,
        owner,
        name: formData.summary.name,
        ticker: formData.summary.symbol,
        type: formData.termsandconditions.branch || "presale",
        liquidity: formData.tokenomics.percent_towards_liquidity,
        liquidityHost: formData.tokenomics.liquidity_provider,
        info: {
          description: formData.information.project_description,
          reddit: formData.information.reddit,
          github: formData.information.github,
          discord: formData.information.discord,
          instagram: formData.information.instagram,
          medium: formData.information.medium,
          facebook: formData.information.facebook,
          website: formData.information.website,
          telegram: formData.information.telegram,
          twitter: formData.information.twitter,
          explorerAddress: getExplorerLink(
            formData.token.tokenAddress,
            network.chainId
          ),
          linktree: formData.information.linktree,
          youtube: formData.information.youtube,
          tiktok: formData.information.tiktok,
          linkedin: formData.information.linkedin,    
          whitepaper: formData.information.whitepaper,
          auditurl: formData.information.auditurl,
          kycurl: formData.information.kycurl,
          adsvideourl: formData.information.adsvideo,
          userexplorer: formData.information.userexplorer,
          imagePath: null,
        },
        
        startTime: formData.tokenomics.start_date,
        endTime: formData.tokenomics.end_date,
        tokenAddress: formData.token.tokenAddress,
        tokenDecimals: +formData.summary.decimals,
        chainId: network.chainId,
        presaleTransactionHash: null,
        presaleContractAddress: null,
      }

      if (formData.termsandconditions.branch === "presale") {
        body.details = {
          minPurchase: formData.tokenomics.min_contribution,
          maxPurchase: formData.tokenomics.max_contribution,
          hardCap: formData.tokenomics.hard_cap,
          softCap: formData.tokenomics.soft_cap,
          baseSymbol: formData.tokenomics.base_symbol,
          status: 0,
          lockTime: formData.tokenomics.ito_liquidity,
          rate: formData.tokenomics.presale_rate,
          launchPrice: formData.tokenomics.launch_price,
          inversedRate: formData.tokenomics.base_symbol !== nativeCoin.symbol,
          tokenSupply: formData.summary.supply.toFixed(),
        }
      } else {
        body.fairLaunchDetails = {
          tokenSupply: formData.summary.supply.toFixed(),
          amountForSale: formData.tokenomics.amount_for_sale,
          minPurchase: formData.tokenomics.min_contribution,
          maxPurchase: formData.tokenomics.max_contribution,
          softCap: formData.tokenomics.soft_cap,
          baseSymbol: formData.tokenomics.base_symbol,
          status: 0,
          lockTime: formData.tokenomics.ito_liquidity,
          inversedRate: formData.tokenomics.base_symbol !== nativeCoin.symbol,          
        }
      }

      return body
    }
  }

  const setSummary = summary => {
    setFormData({ ...formData, summary })
  }

  return (
    <Row className="tab-outer">
      <NetworkSetter chainId={"any"}></NetworkSetter>

      <Col lg={8} className="mb-sm-5 mx-auto mb-4">
        <ul
          className={`circleopticy tab-circles progress-${tab} list-unstyled d-flex align-items-center justify-content-between`}
        >
          <li>
            <button
              onClick={() => setTab(1)}
              className={tab > 0 ? "active" : null}
              disabled={tab > 0 ? false : true}
            >
              <span>1</span>
            </button>
          </li>
          <li>
            <button
              onClick={() => setTab(2)}
              className={tab > 1 ? "active" : null}
              disabled={tab > 1 ? false : true}
            >
              <span className="">2</span>
            </button>
          </li>
          <li>
            <button
              onClick={() => setTab(3)}
              className={tab > 2 ? "active" : null}
              disabled={tab > 2 ? false : true}
            >
              <span>3</span>
            </button>
          </li>
          <li>
            <button
              onClick={() => setTab(4)}
              className={tab > 3 ? "active" : null}
              disabled={tab > 3 ? false : true}
            >
              <span>4</span>
            </button>
          </li>
        </ul>
      </Col>

      <div className="w-100 mb-lg-5"></div>

      {tab === 1 ? (
        <Col lg={12} className="mb-4">
          <Terms
            isFairLaunch={isFairLaunch}
            noToFairLaunch={noToFairLaunch}
            yesToFairLaunch={yesToFairLaunch}
            nextButtonValidate={isNextEnabled[1]}
            onNext={setNextEnabled}
            getValue={() => setTab(2)}
            onSetNetwork={setNetwork}
          />
        </Col>
      ) : (
        <>
          <Col lg={8} className="mb-4">
            {tab === 2 && (
              <SelectToken
                tabEnabled={isNextEnabled[2]}
                nextButtonValidate={isNextEnabled[2]}
                setSummary={setSummary}
                values={formData["token"]}
                formData={formData}
                onNext={setNextEnabled}
              />
            )}
            {tab === 3 && (
              <Tokenomics
                tabEnabled={isNextEnabled[3]}
                changeliquidityprovider={changeliquidityprovider}
                isFairLaunch={isFairLaunch}
                nextButtonValidate={isNextEnabled[3]}
                values={formData["tokenomics"]}
                onNext={setNextEnabled}
                formData={formData}
                network={network?.chainId}
                baseTokens={baseTokens}
                branch={formData.termsandconditions.branch}
                setBaseSymbolAndRate={setBaseSymbolAndRate}
              />
            )}
            {tab === 4 && (
              <Information
                tabEnabled={isNextEnabled[4]}
                network={network}
                nextButtonValidate={isNextEnabled[4]}
                values={formData["information"]}
                onNext={setNextEnabled}
                formData={formData}
              />
            )}
          </Col>
          <Col lg={4} className="mb-4">
            <Summary
              decimals={"decimals"}
              tab={tab}
              formData={formData}
              isFairLaunch={isFairLaunch}
              tokenomics={formData["tokenomics"]}
              nextButtonValidate={isNextEnabled[tab]}
              selectedNetwork={network}
              type={formData["termsandconditions"].branch}
              getValue={() => (tab === 4 ? submit() : setTab(tab + 1))}
            />
          </Col>
        </>
      )}
    </Row>
  )
}

export default CreateContainer
