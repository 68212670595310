import React, { useEffect, useState } from "react";
import { Col, FormControl, InputGroup, Row } from "react-bootstrap";
import { Paginated } from "../containers/paginated";
import { useIdos } from "../state/idos";
import "../styles/_idoNewDesign.scss";
import hpadLogo from "./../images/hpadLogo.png";
import searchIcon from "./../images/Seacrh.png";
import HpadIdo from "../components/ido/basic/hpad-ido-preview";
import { useDebouncedCallback } from "use-debounce";

const IdoList = ({ uncomidos }) => {
  return (
    <Row className="w-100 mt-md-5 ido-list">
      {uncomidos &&
        uncomidos.map((uncomido, index) => (
          <Col sm={12} md={12} lg={12} xl={6} className="vault mb-md-5 mb-4" key={index}>
            <HpadIdo uncomido={uncomido} />
          </Col>
        ))}
    </Row>
  );
};

const useIsClient = () => {
  const [isClient, setClient] = useState(false);
  const key = isClient ? "client" : "server";

  useEffect(() => {
    setClient(true);
  }, []);

  return { isClient, key };
};

function Idos() {
  const { isClient } = useIsClient();
  const { paginate, onSearchByName, pagination } = useIdos();
  const { idos } = useIdos();
  const [value, setValue] = useState();
  const [searchLoading, setSearchLoading] = useState(false);
  const debounced = useDebouncedCallback(value => {
    setValue(value);
    onSearchByName({ target: { value } });
    setSearchLoading(false);
  }, 4000);

  if (!isClient) {
    return null;
  }
  return (
    <Row className="mb-2 ido-page">
      <Col md={12} className="mb-md-4 mb-5">
        <div className="ido-search-bar">
          <span style={{ marginRight: 15 }}>
            <img
              src={hpadLogo}
              className="hpadLogo"
              onClick={() => {
                pagination(1);
              }}
            ></img>
          </span>
          <span style={{ width: "100%", marginRight: 20 }}>
            <InputGroup className="form-hpay-input-group ido-search">
              <span className="search-bar-icon">
                <span>
                  <img src={searchIcon} style={{ height: 30, paddingLeft: 10, paddingBottom: 4 }}></img>
                </span>
                <FormControl
                  placeholder="Search token name or contract address..."
                  // onChange={onSearchByName}
                  onChange={e => {
                    debounced(e.target.value);
                    setSearchLoading(true);
                  }}
                  className="form-hpay ido-search-placeholder"
                />
                <span>{searchLoading ? <div className="position-absolute loading-spinner"></div> : null}</span>
              </span>
            </InputGroup>
          </span>
        </div>
      </Col>
      <Col md={12} className="mt-3 mt-md-0 mb-md-5 mb-4 d-flex justify-content-center flex-wrap gap p-3">
        <IdoList uncomidos={idos} />
      </Col>
      <Col md={12} className="mb-md-5 mb-4 d-flex justify-content-center flex-column align-items-center">
        <Paginated
          previousClassName="numberpagepara"
          previousLinkClassName="pagenumbertext"
          nextClassName="numberpagepara"
          nextLinkClassName="pagenumbertext"
          pageClassName="numberpagepara2"
          pageLinkClassName="pagenumbertext"
          activeClassName="active"
          nextLabel="next"
          onPageChange={({ selected }) => pagination(selected + 1)}
          pageCount={paginate.totalPages}
          previousLabel="prev"
          renderOnZeroPageCount={null}
        />
      </Col>
    </Row>
  );
}

export default Idos;
