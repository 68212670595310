import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button, Col, Row, Modal } from "react-bootstrap";
import NetworkContext from "../../../../context/network-context";
import { useRefreshWhilteListed } from "../../../../state/idos";
import { fToken } from "../../../../utils/formatNumber";
import { fetchTokenBalance, useApprove } from "../../../../web3/account";
import { useIdoAdminActions } from "../../../../web3/idos";
import Whitelist from "../../../whitelist-manager/whitelist-manager";
import InfoForm from "./ido-info-form";

function AdminPanel({ token, presaleContractAddress, chainId, whiteListManager, ...ido }) {
  const { account, connected, isCorrectNetwork, pendingTransaction, executeTransaction } = useContext(NetworkContext);
  const { fundPresale, finalizePresale } = useIdoAdminActions(presaleContractAddress, account);
  const { approve, isApproved } = useApprove(token, account, presaleContractAddress);

  const [, refreshWhitelistStatus] = useRefreshWhilteListed(presaleContractAddress);
  const [supply, setSupply] = useState();
  const [hasWhitelist, setHasWhitelist] = useState();
  const [funded, setFunded] = useState();
  const [contractBalance, setContractBalance] = useState();
  const [updateshow, setUpdateShow] = useState(false);
  const [IsAfterPresale, setIsAfterPresale] = useState(false);

  const handleUpdateModal = () => setUpdateShow(!updateshow);

  // useEffect(() => {
  //   console.log(ido)
  //   console.log(presaleContractAddress)
  //   console.log(account)
  //   console.log(token)
  //   console.log( "contractBalance",contractBalance)
  //   console.log("supply",supply)
  // }, [ido]);

  useEffect(() => {
    setFunded(contractBalance >= supply);
  }, [contractBalance, supply, ido]);

  const updateBalance = useCallback(async () => {
    const f = async () => {
      if (!token || !presaleContractAddress || !chainId) {
        return;
      }

      const balance = await fetchTokenBalance(token, presaleContractAddress, chainId);
      setContractBalance(balance);
    };
    f();
  }, [chainId, token, presaleContractAddress]);

  useEffect(() => {
    if (!ido) {
      return;
    }
    if (ido.status <= 1) {
      setIsAfterPresale(false);
    } else {
      setIsAfterPresale(true);
    }
    setSupply(ido.requiredFunds);
    setHasWhitelist(ido.whitelisted);
    updateBalance();
  }, [ido, isApproved, setHasWhitelist, updateBalance]);

  const handleApprove = useCallback(async () => {
    const tx = async () => approve();

    await executeTransaction({
      message: "Approving",
      tx
    });
  }, [approve, executeTransaction]);

  const handleFund = useCallback(async () => {
    const tx = async () => fundPresale();

    await executeTransaction({
      message: "Sending..,",
      tx
    });
  }, [fundPresale, executeTransaction]);

  const handleFinalize = useCallback(async () => {
    const tx = async () => finalizePresale();

    await executeTransaction({
      message: "Ending Presale",
      tx
    });
  }, [finalizePresale, executeTransaction]);

  const handleWhitelistEvent = useCallback(() => {
    refreshWhitelistStatus(account);
  }, [refreshWhitelistStatus, account]);

  return (
    <>
      <Row>
        <Col md={6} className="">
          {hasWhitelist && (
            <div className="height704">
              <div className="h-100 tab-box ">
                <div className="h-100 tab-box-inner tab-box-inner-lg">
                  <Whitelist
                    managerAddress={whiteListManager}
                    whitelistId={presaleContractAddress}
                    onEvent={handleWhitelistEvent}
                  ></Whitelist>
                </div>
              </div>
            </div>
          )}
        </Col>
        <Modal show={updateshow} onHide={handleUpdateModal} className="showupdateshowmodel">
          <div className="tab-box">
            <div className="tab-box-inner tab-box-inner-lg">
              <InfoForm handleUpdateModal={handleUpdateModal} values={ido.socials} address={presaleContractAddress} />
            </div>
          </div>
        </Modal>

        <Col md={6}>
          <Row className="gap-3">
            <div className="tab-box">
              <div className="tab-box-inner tab-box-inner-lg">
                <div className="d-flex justify-content-between">
                  <h2 className="popin-700-30">IDO Manager</h2>
                  <div>
                    <Button onClick={handleUpdateModal} className="btn-sm text-white">
                      Update
                    </Button>
                  </div>
                </div>

                <p className="popin-400-16 grey1-text mb-0 nooptty">Please ensure that the presale address</p>
                <strong className="popin-400-16 gold-text me-1">{presaleContractAddress}</strong>
                <span className="popin-400-16 grey1-text">
                  is excluded from any transaction mechanics such as fees, rewards or reflections and that it can freely
                  accept the presale supply.
                </span>
              </div>
            </div>
            {ido && ido.type !== "PROXY_FUNDED" && (
              <div className="tab-box">
                <div className="tab-box-inner tab-box-inner-lg">
                  <h3 className="mb-0 popin-600-24 mb-4">Funding Status</h3>
                  <div className="d-flex justify-content-between popin-500-16 mb-3">
                    <span className="grey1-text">
                      {fToken(contractBalance)} {!funded && "/" + fToken(supply)} {ido.ticker}
                    </span>
                    <div>
                      {funded && <span className="m-0 gold-text">This presale was funded</span>}
                      {!funded && <span className="m-0 gold-text">Please Fund The presale</span>}
                    </div>
                  </div>
                  <div>
                    {!funded ? (
                      <>
                        {isApproved ? (
                          <>
                            <Button
                              onClick={handleFund}
                              disabled={!connected || !isCorrectNetwork || !!pendingTransaction || IsAfterPresale}
                              className="btn text-white w-100"
                            >
                              Fund Presale
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              onClick={handleApprove}
                              disabled={!connected || !isCorrectNetwork || !!pendingTransaction}
                              className="btn text-white w-100"
                            >
                              Approve
                            </Button>
                          </>
                        )}
                      </>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ width: 100, height: 100, margin: "auto" }}
                        width="16"
                        height="16"
                        fill="green"
                        className="bi bi-check2-circle"
                        viewBox="0 0 16 16"
                      >
                        <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
                        <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
                      </svg>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="tab-box">
              <div className="tab-box-inner tab-box-inner-lg">
                <h3 className="popin-600-24 mb-4">Finalize</h3>
                <p className="popin-400-16 grey1-text">
                  Presale can be finalized only after hardcap is reached or when the soft cap is rasied and the timer
                  expires
                </p>

                <Button
                  onClick={handleFinalize}
                  disabled={
                    ido?.finalized || ido.status !== 2 || !connected || !isCorrectNetwork || !!pendingTransaction
                  }
                  className="btn text-white w-100"
                >
                  Finalize
                </Button>
              </div>
            </div>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default AdminPanel;
