
import React from 'react';
import { useIdoExchangePairs } from '../../../web3/token-utils';
import CoinSelectInput2 from "../../coin-select-input/coin-select-input2";


function IdoCoinSelectInput({ receiving, ido, base,bnbBalance,tokenBalance, targetChainId, ...props  }) {
    const coins = useIdoExchangePairs(targetChainId);


    return <CoinSelectInput2
        showSearch={false}
        showMax={true}
        coins={coins}
        receiving={receiving}
        ido={ido}
        base={base}
        targetChainId={targetChainId}
        bnbBalance={bnbBalance}
        tokenBalance={tokenBalance}
        {...props} />;
}

export default IdoCoinSelectInput;
