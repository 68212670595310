import React, { useState, useEffect, useCallback, useContext } from "react";
import sales from "./../../../images/sales-hpad.png";
import AsyncItem from "components/misc/AsyncItem";
import { Modal } from "react-bootstrap";
import IdoConfirm2 from "components/ido/common/ido-confirm2";
import { getIdoExchangePairToken, useNativeCoin } from "web3/token-utils";
import BigNumber from "bignumber.js";
import { getDefaultRouterAddress } from "utils/utils";
import NetworkContext from "context/network-context";
import { useIdoActions } from "web3/fair-launch";
import { useRelativeTokenPrice } from "web3/price-data";
import { useUserInvestment } from "state/idos";
import { networks } from "web3/web3";

export default function SaleDropDown({ ido, uncomido }) {
  const { account, connected, isCorrectNetwork, network, pendingTransaction, executeTransaction } = useContext(
    NetworkContext
  );

  const { buyWithBase, swapAndBuy } = useIdoActions(uncomido.presaleContractAddress, account);
  const [isValid, setIsValid] = useState(false);
  const [modalStatus, setModalStatus] = useState(null);
  const [base, setBase] = useState();
  const [inputValue, setInputValue] = useState(0.1);
  const nativeCoin = useNativeCoin(uncomido.chainId);
  const [receiving, setReceiving] = useState(0);
  const [capital, setCapital] = useState(0);
  const [idoBaseValue, setIdoBaseValue] = useState();
  const [idoBaseCoin, setIdoBaseCoin] = useState();
  const [showConfirm, setShowConfirm] = useState(false);
  const [rate, setRate] = useState(0);
  const [remaining, setRemaning] = useState(0);

  const { investedAmount } = useUserInvestment(uncomido.presaleContractAddress);

  const [relativeTokenPrice] = useRelativeTokenPrice(
    base,
    idoBaseCoin,
    getDefaultRouterAddress(uncomido.chainId),
    uncomido.chainId
  );

  const [relativeValidationPrice] = useRelativeTokenPrice(
    base,
    getDefaultRouterAddress(uncomido.chainId),
    uncomido.chainId
  );

  useEffect(() => {
    if (!ido) {
      return;
    }
    const coin = getIdoExchangePairToken(uncomido.details.baseSymbol, uncomido.chainId);
    setBase(coin);
    setRate(uncomido.details.rate);
    setCapital(ido?.capital);

    const itibase = getIdoExchangePairToken(uncomido.details.baseSymbol, uncomido.chainId);
    setIdoBaseCoin(itibase);
    setIdoBaseValue(inputValue);
  }, [base, ido, modalStatus]);

  const calculateRelativeValue = useCallback(
    (capital, value = 0) => {
      let relativeValue;
      let _rate = +rate;
      if (!relativeTokenPrice || !rate) {
        return 0;
      }

      if (ido.type === "fairlaunch") {
        if (capital === 0) {
          return ido.amountForSale;
        } else {
          _rate = ido.amountForSale / capital;
        }
      }

      if (base.symbol !== ido.baseSymbol) {
        relativeValue = ((value / relativeTokenPrice) * _rate).toFixed(8);
      } else {
        relativeValue = (value * _rate).toFixed(8);
      }

      return relativeValue;
    },
    [ido, base, rate, relativeTokenPrice]
  );

  const getCorrectedBaseBase = useCallback(() => {
    if (!ido) {
      return;
    }
    let baseValue = inputValue;
    let initialValue = baseValue;
    if (base.symbol !== ido.baseSymbol) {
      baseValue = +new BigNumber(baseValue).div(relativeValidationPrice).toFixed(8, BigNumber.ROUND_UP);
    }

    if (+remaining < uncomido.details.minPurchase) {
      baseValue = uncomido.details.minPurchase * 1.0005;
      if (base.symbol !== ido.baseSymbol) {
        baseValue = +new BigNumber(baseValue).times(relativeValidationPrice).toFixed(8, BigNumber.ROUND_UP);
      }
      return baseValue;
    } else {
      return initialValue;
    }
  }, [base, inputValue, relativeValidationPrice, remaining]);

  function quickswap() {
    let _remainingUntilHardCap = Infinity;
    if (ido.hardCap) {
      _remainingUntilHardCap = new BigNumber(ido.hardCap).minus(ido.capital);
    }

    const _remainingUntilMaxBuy = new BigNumber(ido.maxPurchase).minus(investedAmount || 0);
    const _remaning = BigNumber.min(_remainingUntilHardCap, _remainingUntilMaxBuy);

    setRemaning(+_remaning.toFixed(8, BigNumber.ROUND_UP));

    let value = inputValue;

    let tx;
    let baseValue = getCorrectedBaseBase();
    const relativeValue = calculateRelativeValue(capital, value);
    setReceiving(relativeValue);
    const config = {
      quoteValue: receiving,
      idoBaseValue,
      idoBaseCoin,
      baseValue,
      base,
      quoteSymbol: uncomido.ticker,
      relativeTokenPrice,
      ido,
      uncomido,
      price: rate,
      quoteTokenIcon: uncomido.info.imagePath
    };
    if (base.symbol === uncomido.details.baseSymbol) {
      setModalStatus({
        ...config,
        mode: "basic",
        action: async () => {
          tx = async () => await buyWithBase(baseValue);
          try {
            await executeTransaction({
              message: "Executing...",
              tx
            });
          } catch (error) {
            console.log(error);
          }
          setShowConfirm(false);
        }
      });
    } else {
      setModalStatus({
        ...config,
        mode: "hop",
        action: async minAmount => {
          tx = async () => await swapAndBuy(base.address, baseValue, minAmount, base.address === nativeCoin.address);
          try {
            await executeTransaction({
              message: "Executing...",
              tx
            });
          } catch (error) {
            console.log(error);
          }
          setShowConfirm(false);
        }
      });
    }
    setShowConfirm(true);
  }
  return (
    <>
      <AsyncItem>
        <img loading="lazy" src={sales}></img>
        <div>
          Buy
          <span className="ms-1">
            <img loading="lazy" src={"/icons/chevron-down.svg"} style={{ height: 10 }}></img>
          </span>
          <div className="lower-hover-div">
            <button
              className="lower-hover-btn"
              role="button"
              onClick={() => {
                setInputValue(0.1);
                quickswap();
              }}
              disabled={!connected || !isCorrectNetwork || !!pendingTransaction}
            >
              0.1
              <span>
                <img loading="lazy" src={`/idoicon/woBG/${networks[uncomido.chainId]?.shortName}.svg`} className="lower-hover-images"></img>
              </span>
            </button>
            <button
              className="lower-hover-btn"
              role="button"
              onClick={() => {
                setInputValue(0.2);
                quickswap();
              }}
              disabled={!connected || !isCorrectNetwork || !!pendingTransaction}
            >
              0.2
              <span>
                <img loading="lazy" src={`/idoicon/woBG/${networks[uncomido.chainId]?.shortName}.svg`} className="lower-hover-images"></img>
              </span>
            </button>
            <button
              className="lower-hover-btn"
              role="button"
              onClick={() => {
                setInputValue(0.5);
                quickswap();
              }}
              disabled={!connected || !isCorrectNetwork || !!pendingTransaction}
            >
              0.5
              <span>
                <img loading="lazy" src={`/idoicon/woBG/${networks[uncomido.chainId]?.shortName}.svg`} className="lower-hover-images"></img>
              </span>
            </button>
            <button
              className="lower-hover-btn"
              role="button"
              onClick={() => {
                setInputValue(1);
                quickswap();
              }}
              disabled={!connected || !isCorrectNetwork || !!pendingTransaction}
            >
              1.0
              <span>
                <img loading="lazy" src={`/idoicon/woBG/${networks[uncomido.chainId]?.shortName}.svg`} className="lower-hover-images"></img>
              </span>
            </button>
          </div>
        </div>
      </AsyncItem>
      <Modal className="stake-modal" aria-labelledby="contained-modal-title-vcenter" centered show={!!showConfirm} onHide={() => setShowConfirm(false)}>
        <Modal.Body>
          <IdoConfirm2 {...modalStatus}></IdoConfirm2>
        </Modal.Body>
      </Modal>
    </>
  );
}
