import React from "react";
import { fToken } from "../../../utils/formatNumber";

const Progress = ({ ido, progress, receiving }) => {
  return (
    <>
      <div className="popin-600-16 d-flex justify-content-between mb-3 ">
        <div className="d-flex ">
          <span className="me-1">Raised</span>
          <span className="gold-text">
            {fToken(+ido.capital)} {ido?.baseSymbol}
          </span>
        </div>
        <span className="gold-text">{progress.toFixed(2)}%</span>
      </div>
      <div className="progress idodetailprogress" style={{ borderRadius: "0.25rem" }}>
        <div
          className="progress-bar progress-bar-success "
          style={{ width: progress + "%", borderRadius: "0.25rem" }}
        ></div>
      </div>
      <div className="mt-1 mb-1 popin-500-14 grey3-text d-flex justify-content-between">
        <span>0 {ido?.baseSymbol}</span>
        <span>
          {ido.type === "presale" ? <>{ido?.hardCap}</> : <>{ido?.softCap}</>}
          {ido?.baseSymbol}
        </span>
      </div>
    </>
  );
};

export default Progress;
