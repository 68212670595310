import React, { useContext, useEffect, useState } from "react"
import NetworkContext from "../../context/network-context"

import { useTokenContract } from "../../web3/contracts/contracts"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"

const SelectToken = ({ onNext, values, setSummary, formData }) => {
  const { network, account } = useContext(NetworkContext)

  const [token, setToken] = useState({
    value: values.tokenAddress,
    validate: new RegExp(/^0x[a-fA-F0-9]{40}$/),
    isValid: true,
  })
  const [loading, setLoading] = useState(false)
  let {
    decimals,
    supply,
    balanceOf,
    symbol,
    isValidToken,
    name,
    addressToken,
  } = useTokenContract(token.value, network)

  const onChangeToken = ({ target: { value } }) => {
    setToken({
      ...token,
      value,
      isValid: !value ? true : !!token.validate.test(value),
    })
    setLoading(true)
  }

  useEffect(() => {
    onNext(2, !!symbol && !!token.validate.test(addressToken), "token", {
      presale_creator: values.presale_creator,
      tokenAddress: addressToken,
    })
  }, [addressToken, symbol])

  useEffect(() => {
    const fn = async () => {
      if (
        !decimals ||
        !isValidToken ||
        !supply ||
        !addressToken ||
        !symbol ||
        !name ||
        !account
      ) {
        return
      }
      const balance = await balanceOf(account)
      setLoading(false)
      setTimeout(() => {
        setSummary({
          decimals,
          supply,
          isValidToken,
          balance: !isValidToken ? 0 : balance,
          token: addressToken,
          symbol,
          name,
          addressToken,
        })
      }, 100)
    }
    return fn()
  }, [decimals, isValidToken, supply, addressToken, symbol, name, account])

  return (
    <div className="tab-box">
      <div className="tab-box-inner tab-box-inner-lg">
        <h3 className="hpaycreatehead">
          {formData.termsandconditions.branch === "presale" ? <>Presale</>:<>Fair Launch</>}
        </h3>

        <hr className="mb-3 hpaycreate2line" />
        <div className="d-flex flex-row">
          <label className="mb-3 popin-600-18 gold-text">
            Provide the address of your ERC20 token contract
          </label>
          <span className="hpadrequiredred">*</span>
        </div>
        <div className="position-relative">
          <input
            id="hpadinputplaceholder"
            value={token.value}
            onChange={onChangeToken}
            type="text"
            placeholder="Enter Token Address"
            className="form-control form-hpay"
          />
          {loading ? (
            <>
              <div className="position-absolute loading-spinner"></div>
            </>
          ) : null}
        </div>
        {!token.isValid && (
          <small className="hpadrequiredred">Invalid token address</small>
        )}

        <div className="divider position-relative py-3"></div>
        <p className="mb-3 popin-600-18 gold-text">Token Details</p>

        <div className="mb-4 card-token">
          <div className="card-token-body">
            <div className="card-token-item">
              <div className="mb-4 d-flex align-items-center justify-content-between">
                <h6 className="m-0 connectwallettextpricingwhite">Name</h6>
                <span className="popin-600-16 gold-text">
                  {formData.summary.name ? (
                    <>{formData.summary.name}</>
                  ) : (
                    <>&#45;</>
                  )}
                </span>
              </div>
              <div className="mb-4 d-flex align-items-center justify-content-between">
                <h6 className="m-0 connectwallettextpricingwhite">
                  Token Symbol
                </h6>
                <span className="connectwallettextpricingwhite">
                  {formData.summary.symbol ? (
                    <>{formData.summary.symbol}</>
                  ) : (
                    <>&#45;</>
                  )}
                </span>
              </div>
              <div className="mb-4 d-flex align-items-center justify-content-between">
                <h6 className="m-0 connectwallettextpricingwhite">
                  Total Supply
                </h6>
                <span className="connectwallettextpricingwhite">
                  {formData.summary.supply ? (
                    <>{formData.summary.supply}</>
                  ) : (
                    <>&#45;</>
                  )}
                </span>
              </div>
              <div className="mb-4 d-flex align-items-center justify-content-between">
                <h6 className="m-0 connectwallettextpricingwhite">Decimals</h6>
                <span className="connectwallettextpricingwhite">
                  {formData.summary.decimals ? (
                    <>{formData.summary.decimals}</>
                  ) : (
                    <>&#45;</>
                  )}
                </span>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <h6 className="m-0 connectwallettextpricingwhite">
                  Token Contract
                </h6>
                <span className="connectwallettextpricingwhite">
                  {formData.summary.token ? (
                    <>
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-top">Copy Token Address</Tooltip>
                        }
                      >
                        <div
                          role="button"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              formData.summary.token
                            )
                          }}
                        >
                          {formData.summary.token.substring(0, 4)}...{" "}
                          {formData.summary.token.substr(
                            formData.summary.token.length - 4
                          )}
                        </div>
                      </OverlayTrigger>
                    </>
                  ) : (
                    <>&#45;</>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
// 0x2AC71377AE5e30Bf31113ea99195C89f25CE146b
export default SelectToken
