import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Countdown from "react-countdown";
import NetworkContext from "../../../context/network-context";
import {
  useCurrentIdo,
  useRefreshIdo,
  useUserBalanaceAmount,
  useUserInvestment,
  useUserIsAdmin,
  useRefreshUserBalanceAmount
} from "../../../state/idos";
import { useIdoAddress } from "../../../utils/utils";
import { useIdoActions } from "../../../web3/fair-launch";

import EmergencyWithdraw from "../common/emergency-withdraw";
import Progress2 from "./../common/ido-progress2";
import AdminPanel from "../common/admin/admin-panel";
import IdoHeader2 from "../common/ido-header2";
import IdoVesting from "../common/ido-vesting";

import IdoClaim from "./ido-claim";
import IdoStats2 from "./ido-stats2";
import IdoSwap from "./ido-swap";
import { useInterval } from "react-use";

function IdoPad() {
  const idoAddress = useIdoAddress();
  const { ido, status: loadingStatus } = useCurrentIdo(idoAddress);
  const { account } = useContext(NetworkContext);

  const [, refreshIdo] = useRefreshIdo();
  const [receiving, setReceiving] = useState(0);

  const [progress, setProgress] = useState(0);

  const { investedAmount } = useUserInvestment(idoAddress);
  const { emergencyWithdraw, refund } = useIdoActions(idoAddress, account);
  const { userBalance } = useUserBalanaceAmount(idoAddress);
  const { isAdmin } = useUserIsAdmin(idoAddress);

  useEffect(() => {
    if (!ido) {
      return;
    }
    setReceiving(investedAmount * ido.rate);
    setProgress(Math.min(100, (ido.capital * 100) / ido.softCap));
  }, [ido]);

  useInterval(() => {
    refreshIdo(idoAddress);
  }, 5000);
  const handleComplete = async () => {
    refreshIdo(idoAddress);
  };

  return (
    <>
      <Row className="mb-5">
        <Col className="d-flex flex-column justify-content-center">
          <h1 className="text-center popin-700-38">HPAD</h1>
          <div className="mb-3 mx-auto centergoldenline"></div>
          <p className="popin-400-16 mx-auto idodetailpara text-center grey2-text">
            HPAD offers an affordable solution to kickstart your project with ease. Our exclusive, unique, and secure
            services provide guidance every step of the way, assisting in your success.
          </p>
        </Col>
      </Row>

      <IdoHeader2 idoInfo={ido} address={idoAddress}></IdoHeader2>

      <Row className="my-5">
        <Col md={8}>
          <IdoStats2
            idoInfo={ido}
            address={idoAddress}
            receiving={receiving}
            investedAmount={investedAmount}
            userBalance={userBalance}
            refund={refund}
          />
          {ido && Object.keys(ido?.vesting).length > 0 && <IdoVesting address={idoAddress}></IdoVesting>}
        </Col>

        <Col md={4}>
          <div className="mt-4 mt-md-0 idoheaderbackgroundright idoswaphieght">
            {ido && !!!loadingStatus.loading && (
              <>
                <Progress2
                  investedAmount={investedAmount}
                  receiving={receiving}
                  ido={ido}
                  progress={progress}
                ></Progress2>
              </>
            )}
            {!!!loadingStatus.loading && (
              <>
                {(() => {
                  switch (ido.status) {
                    case 0:
                      return <IdoSwap ido={ido} address={idoAddress}></IdoSwap>;
                    case 1:
                      return (
                        <>
                          <IdoSwap ido={ido} address={idoAddress}></IdoSwap>
                          {investedAmount > 0 ? (
                            <div className="text-center mt-2 mb-md-0">
                              <EmergencyWithdraw
                                emergencyWithdraw={emergencyWithdraw}
                                baseSymbol={ido && ido.baseSymbol}
                              ></EmergencyWithdraw>
                            </div>
                          ) : null}
                        </>
                      );
                    case 2:
                      return <IdoClaim address={idoAddress}></IdoClaim>;
                    case 3:
                      return <IdoClaim address={idoAddress}></IdoClaim>;
                    default:
                      return (
                        <>
                          <h3 className="mb-0 text-white text-center text-lg-start d-flex align-items-center justify-content-center popin-600-24">
                            Something is wrong
                          </h3>
                        </>
                      );
                  }
                })()}
              </>
            )}
          </div>
          <div className="hpaycreate3line"></div>
          <div className="idoswapdownhieght">
            {ido ? (
              <>
                {(() => {
                  switch (ido?.status) {
                    case 0:
                      return (
                        <>
                          <div className="text-center d-flex flex-row justify-content-center">
                            <span className="me-1 popin-500-16 grey3-text">Upcoming</span>
                            <span className="popin-600-16 text-primary-gradient">
                              <Countdown key={ido.startTime} date={ido.startTime}></Countdown>
                            </span>
                          </div>
                        </>
                      );
                    case 1:
                      return (
                        <>
                          <div className="text-center d-flex flex-row justify-content-center">
                            <span className="me-1 popin-500-16 grey3-text">Ends In</span>
                            <span className="popin-600-16 text-primary-gradient">
                              <Countdown key={ido.endTime} date={ido.endTime}></Countdown>
                            </span>
                          </div>
                        </>
                      );
                    case 2:
                      return (
                        <>
                          <div className="text-center d-flex flex-row justify-content-center">
                            <span className="me-1 popin-500-16 grey3-text">Ended</span>
                          </div>
                        </>
                      );
                    case 3:
                      return (
                        <>
                          <div className="text-center d-flex flex-row justify-content-center">
                            <span className="me-1 popin-500-16 grey3-text">Ended</span>
                          </div>
                        </>
                      );
                    default:
                      return null;
                  }
                })()}
              </>
            ) : null}
          </div>
        </Col>
      </Row>

      {ido && isAdmin && (
        <Row className="mb-2 ">
          <AdminPanel {...ido}> </AdminPanel>
        </Row>
      )}
    </>
  );
}

export default IdoPad;
