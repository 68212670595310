import AutoNumeric from "autonumeric";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Badge, Col, FormControl, InputGroup, Modal } from "react-bootstrap";
import { useDebounce } from "react-use";
import networkIcon from "../../images/chain-icon";
import { networks } from "../../web3/web3";
import AsyncItem from "../misc/AsyncItem";
import { fToken } from "../../utils/formatNumber";
import { useNativeCoin } from "../../web3/token-utils";

const CoinItemSelect = ({ image, symbol, onSelect, onRemove, address, chainId, unverified }) => (
  <div
    role="button"
    onKeyPress={onSelect}
    tabIndex={0}
    onClick={() => onSelect()}
    className="modal-row-item coin-item-dropdown-select align-items-center"
  >
    <div>
      <h5>
        {symbol}
        <img
          style={{ width: "15px", height: "15px", marginLeft: "0.75rem" }}
          src={networkIcon[networks[+chainId].shortName]}
          alt="icon"
        />
        {unverified && (
          <Badge pill className="ms-2 badge-primary border-0">
            <span className="text-primary-gradient ">Unverified</span>
          </Badge>
        )}
      </h5>
      <small className="generic-label--small-thin">
        {networks[chainId].shortName} : {address.slice(0, 5)}...
        {address.slice(30)}
      </small>
    </div>
    <div>
      {unverified && (
        <Badge
          pill
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            onRemove();
          }}
          className="me-2 badge-primary clickable"
        >
          <span className="text-primary-gradient ">Remove</span>
        </Badge>
      )}
      <img src={image} alt={symbol} width={45} height={45} />
    </div>
  </div>
);

function CoinSelectInput({
  onSelect,
  defaultSelected,
  onInput,
  onSearch,
  onRemove,
  showSearch,
  fetching,
  coins,
  selected,
  value,
  receiving,
  ido,
  base,
  targetChainId,
  bnbBalance,
  tokenBalance,
  showMax,
  handleMax,
  handle25per,
  handle0per,
  handle75per,
  handle50per
}) {
  const [numericHandle, setNumericHandle] = useState(null);
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState();
  const [perBtnState, setPerBtnState] = useState("per0");

  const input = useRef(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const nativeCoin = useNativeCoin(targetChainId);
  const handleSelect = useCallback(
    value => {
      if (onSelect) {
        onSelect(value);
      }
      setShow(false);
    },
    [onSelect]
  );

  useEffect(() => {
    if (!numericHandle) {
      return;
    }
    numericHandle.set(value);
  }, [value, numericHandle]);

  useDebounce(
    () => {
      if (onSearch) {
        onSearch(search);
      }
    },
    100,
    [search]
  );

  useEffect(() => {
    if (!coins || selected) {
      return;
    }
    let choosingCoin = coins[coins.length - 1];
    let _selected = defaultSelected || choosingCoin;
    if (_selected) {
      onSelect(_selected);
    }
  }, [defaultSelected, coins]);

  useEffect(() => {
    if (!selected || numericHandle !== null) {
      return;
    }
    const handle = new AutoNumeric(input.current, 0, {
      decimalPlaces: 8,
      minimumValue: 0,
      maximumValue: Number.MAX_SAFE_INTEGER,
      showWarnings: false,
      allowDecimalPadding: false,
      modifyValueOnWheel: false,
      emptyInputBehavior: 0,
      decimalCharacterAlternative: ","
    });
    setNumericHandle(handle);
  }, [input, selected]);

  return (
    <>
      <AsyncItem loading={!!!selected} height={45}>
        <div className="coin-select-input-wrapper2">
          <div className="coin-select-input2 ">
            <input
              className="w-100 popin-600-18"
              type="text"
              autoComplete="off"
              inputMode="decimal"
              name="input"
              onChange={() => {
                onInput(numericHandle.getNumber());
              }}
              ref={input}
            />
            <div
              className="input-symbol2 d-flex align-items-center justify-content-center"
              role="button"
              onKeyPress={handleShow}
              onClick={handleShow}
              tabIndex={0}
            >
              {selected && (
                <img
                  style={{
                    width: "25px",
                    height: "25px"
                  }}
                  src={`/idoicon/${selected?.symbol}.png`}
                  alt="icon"
                />
              )}
              <span className="popin-600-16 me-1">{selected?.symbol}</span>
              <img src={"/icons/dropdownArrow.svg"} alt="dropdown Arrow" />
            </div>
          </div>
        </div>
        <div className="mt-2 d-flex align-items-center justify-content-between popin-500-14">
          <div className="">
            <span className="grey3-text me-1">You Get:</span>
            <span>
              {receiving < 0.01 ? <>{Math.round(receiving)}</> : <>{fToken(receiving)}</>}
              &nbsp;
              {ido?.ticker}
            </span>
          </div>
          <div className="">
            <span className="grey3-text me-1">Balance:</span>
            <span>
              {fToken(base && nativeCoin && base.symbol === nativeCoin.symbol ? bnbBalance : tokenBalance)}
              &nbsp;
              {base?.symbol}
            </span>
          </div>
        </div>
        <div className="mt-2 d-flex flex-row justify-content-between align-items-center popin-600-14">
          <span
            role="button"
            className={`d-flex flex-row justify-content-center align-items-center ${
              perBtnState === "per0" ? "coinselectposbtn" : "unactiveswapbtncre"
            }`}
            onClick={() => {
              handle0per();
              setPerBtnState("per0");
            }}
            tabIndex={0}
          >
            <span className={`popin-700-14 ${perBtnState === "per0" ? "none" : "goldhover-text"}`}>%</span>
          </span>
          <span
            role="button"
            className={`d-flex flex-row justify-content-center align-items-center ${
              perBtnState === "per25" ? "coinselectposbtn" : "unactiveswapbtncre"
            }`}
            onClick={() => {
              handle25per();
              setPerBtnState("per25");
            }}
            tabIndex={1}
          >
            25
          </span>
          <span
            role="button"
            className={`d-flex flex-row justify-content-center align-items-center ${
              perBtnState === "per50" ? "coinselectposbtn" : "unactiveswapbtncre"
            }`}
            onClick={() => {
              handle50per();
              setPerBtnState("per50");
            }}
            tabIndex={2}
          >
            50
          </span>
          <span
            role="button"
            className={`d-flex flex-row justify-content-center align-items-center ${
              perBtnState === "per75" ? "coinselectposbtn" : "unactiveswapbtncre"
            }`}
            onClick={() => {
              handle75per();
              setPerBtnState("per75");
            }}
            tabIndex={3}
          >
            75
          </span>
          <span
            role="button"
            className={`d-flex flex-row justify-content-center align-items-center ${
              perBtnState === "per100" ? "coinselectposbtn" : "unactiveswapbtncre"
            }`}
            onClick={() => {
              handleMax();
              setPerBtnState("per100");
            }}
            tabIndex={4}
          >
            100
          </span>
        </div>
      </AsyncItem>
      <Modal aria-labelledby="contained-modal-title-vcenter" centered show={show} onHide={handleClose}>
        <Modal.Body style={{ height: 415 }}>
          {showSearch && (
            <Col md={12} className="mb-md-5 mx-auto">
              <InputGroup className="form-hpay-input-group">
                <FormControl
                  placeholder="Search here..."
                  onChange={e => {
                    setSearch(e.target.value);
                  }}
                  className="form-hpay"
                />
              </InputGroup>
            </Col>
          )}
          <Col md={12} style={{ overflowX: "scroll", maxHeight: 320 }}>
            <div>
              {coins &&
                coins.map((coin, index) => (
                  <CoinItemSelect
                    {...coin}
                    onRemove={() => onRemove(coin)}
                    onSelect={() => handleSelect(coin)}
                    key={index}
                  />
                ))}
            </div>
            {(!coins || coins.length === 0) &&
              !fetching &&
              "Token not found or it has low liquidity... Trying searching by contract address..."}
            {fetching && "Searching..."}
          </Col>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CoinSelectInput;
