import React, { useContext } from 'react';
import NetworkContext from '../../../context/network-context';
import { useCurrentIdo, useUserInvestment, useIsUserWhiteListed } from '../../../state/idos';
import { useIdoActions } from '../../../web3/fair-launch';
import Swap2 from "../common/ido-swap2";

function IdoSwap({ address }) {
    const { account } = useContext(NetworkContext);
    const { ido } = useCurrentIdo(address);
    const { investedAmount } = useUserInvestment(address);
    const { buyWithBase, swapAndBuy } = useIdoActions(address, account);
    const { isWhitelisted } = useIsUserWhiteListed(address)

    return <Swap2 ido={ido} investedAmount={investedAmount} buyWithBase={buyWithBase} swapAndBuy={swapAndBuy} isWhitelisted={isWhitelisted} />
}

export default IdoSwap;
