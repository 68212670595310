import React from "react"
import BasicIdo from "../components/ido/basic/ido-details"
import FairLaunch from "../components/ido/fairlaunch/ido-details"
// import ProxyFundedIdo from "../components/ido/proxy-funded/ido-details"
import NetworkSetter from "../components/network/networksetter"
import { useCurrentIdo } from "../state/idos"
import { useIdoAddress } from "../utils/utils"


function IdoContainer() {
  const idoAddress = useIdoAddress()
  const { ido, refresh } = useCurrentIdo(idoAddress)

  return (
    <>
      {ido && <NetworkSetter chainId={ido.chainId}></NetworkSetter>}
      {ido?.type === "presale" && (
        <BasicIdo refreshIdo={refresh} ido={ido} />
      )}

      {ido && ido.type === "fairlaunch" && (
        <FairLaunch refreshIdo={refresh} ido={ido} />
      )}
      {/* {
            ido && ido.type === 'BASIC' && <BasicIdo refreshIdo={refresh} ido={ido} />
        }

        {
            ido && ido.type === 'PROXY_FUNDED' && <ProxyFundedIdo></ProxyFundedIdo>
        }

        */}
    </>
  )
}

export default IdoContainer
