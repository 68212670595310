import React from "react";
import { Range } from "react-range";

const STEP = 1;
const MIN = 50;
const MAX = 100;

class RangeSlider extends React.Component {

  render() {
    return (
      <div className="range-slider">
        <Range values={this.props.value} step={this.props.STEP || STEP} min={this.props.MIN || MIN} max={this.props.MAX || MAX} onChange={this.props.onChange}
          renderTrack={({ props, children }) => (
            <div
              role="button"
              tabIndex={0}
              onKeyPress={() => { }}
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart} style={{ ...props.style, height: "36px", display: "flex", width: "100%" }}>
              <div className="range-strip" style={{ width: ((+this.props.value - MIN) * 2) + '%' }}></div>
              <div ref={props.ref} style={{ height: "4px", width: "100%", borderRadius: "9px", alignSelf: "center" }}>
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props }) => (
            <div {...props} ><div></div></div>
          )}
        />
      </div>
    );
  }
}

export default RangeSlider;