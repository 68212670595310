
import React, { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useCurrentIdo } from '../../../state/idos';
import * as moment from "moment";

import ExpandIcon from '../../../images/expand-icon.png';
import VestingSchedule from './vesting-schedule';

function IdoVesting({ address }) {
    const { ido } = useCurrentIdo(address);
    const [vesting, setVesting] = useState();
    const [clamingStart, setClamingStart] = useState();
    const [showModal, setShowModal] = useState();
    const handleClose = () => {
        setShowModal(false);
    };

    useEffect(() => {
        if (!ido) {
            return;
        }
        setVesting(ido.vesting);

        if (ido.vesting.claimStart) {
            const clamingStart = moment(ido.vesting.claimStart).format('DD MMM YYYY, HH:mm ');
            setClamingStart(clamingStart);
        }
    }, [setVesting, ido]);


    const VestingInfo = (<>
        <Row className="">
            <Col xs={12} className="mb-3 text-end text-md-start">
                <div className="eco-group">
                    <p>Info</p>
                    <h6 className="text-primary-gradient"> {vesting && vesting.info}</h6>
                </div>
            </Col>
            {ido.vesting.period && <Col xs={6} className="mb-3">
                <div className="eco-group">
                    <p>Vesting Period </p>
                    <h6>
                        {ido.vesting.period}
                        <div
                            role="button"
                            onKeyPress={() => setShowModal(true)}
                            onClick={() => setShowModal(true)}
                            tabIndex="0">
                            <img src={ExpandIcon} width={16} className="ms-2 me-0 clickable" alt="icon" /></div>
                    </h6>
                </div>
            </Col>}

            {clamingStart && <Col xs={6} className="mb-3">
                <div className="eco-group">
                    <p>Claiming Start </p>
                    <h6>
                        {clamingStart}
                    </h6>
                </div>
            </Col>}
        </Row>

        <Modal
            className="stake-modal"
            aria-labelledby="contained-modal-title-vcenter"
            centered show={!!showModal} onHide={handleClose} >

            <Modal.Body>
                <VestingSchedule  {...vesting} handleClose={handleClose}></VestingSchedule>
            </Modal.Body>

        </Modal>
    </>
    );


    return <>
        {vesting && vesting.tba ? "Vesting details to be announced shortly" : VestingInfo}
    </>;
}

export default IdoVesting;
