import BigNumber from "bignumber.js";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import NetworkContext from "../../../context/network-context";
import { getDefaultRouterAddress, roundToEight } from "../../../utils/utils";
import { fetchTokenBalance, getBalance, useApprove } from "../../../web3/account";
import { useRelativeTokenPrice } from "../../../web3/price-data";
import { getIdoExchangePairToken, useNativeCoin } from "../../../web3/token-utils";
import IdoCoinSelectInput from "./ido-coin-select";
import IdoConfirm from "./ido-confirm";
import { roundToSix } from "utils/utils"

function IdoSwap({ ido, investedAmount, buyWithBase, swapAndBuy, isWhitelisted }) {
  const [checked, setChecked] = useState(false);
  const [maxBuy, setMaxBuy] = useState(0);
  const [capital, setCapital] = useState(0);
  const [hardCap, setHardCap] = useState(0);
  const [remaining, setRemaning] = useState(0);

  const [rate, setRate] = useState(0);
  const [idoBaseValue, setIdoBaseValue] = useState();
  const [inputValue, setInputValue] = useState();
  const [idoNativeValue, setIdoNativeValue] = useState();

  const [idoValidationCoin, setIdoValidationCoin] = useState();
  const [idoBaseCoin, setIdoBaseCoin] = useState();
  const [defaultCoin, setDefaultCoin] = useState();

  const [base, setBase] = useState();
  const [chainId, setChainId] = useState();

  const [modalStatus, setModalStatus] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);

  const [bnbBalance, setBNBBalance] = useState(0);
  const [tokenBalance, setTokenBalance] = useState(0);

  const [receiving, setReceiving] = useState(0);
  const [errorMessage, setErrorMessage] = useState();
  const [isValid, setIsValid] = useState(false);
  const [minBuy, setMinBuy] = useState(0);

  const { account, connected, isCorrectNetwork, network, pendingTransaction, executeTransaction } = useContext(
    NetworkContext
  );
  const nativeCoin = useNativeCoin(chainId);
  const { approve, isApproved } = useApprove(base && base.address, account, ido && ido.presaleContractAddress, network, inputValue);

  const [relativeTokenPrice] = useRelativeTokenPrice(base, idoBaseCoin, getDefaultRouterAddress(chainId), chainId);
  const [relativeNativePrice] = useRelativeTokenPrice(base, nativeCoin, getDefaultRouterAddress(chainId), chainId);
  const [relativeValidationPrice] = useRelativeTokenPrice(
    base,
    idoValidationCoin,
    getDefaultRouterAddress(chainId),
    chainId
  );

  const handleClose = useCallback(() => {
    if (!pendingTransaction) {
      setShowConfirm(false);
    }
  }, [pendingTransaction, setShowConfirm]);

  useEffect(() => {
    if (!nativeCoin || !chainId) {
      return;
    }
    const coin = getIdoExchangePairToken(nativeCoin.symbol, chainId);
    setDefaultCoin(coin);
  }, [nativeCoin, chainId]);

  useEffect(() => {
    if (!ido) {
      return;
    }

    const _base = getIdoExchangePairToken(ido.baseSymbol, ido.chainId);
    setIdoBaseCoin(_base);

    const _validationCoin = getIdoExchangePairToken(ido.validationSymbol, ido.chainId);
    if (!_validationCoin) {
      setIdoValidationCoin(_base);
    } else {
      setIdoValidationCoin(_validationCoin);
    }

    setMinBuy(ido.minPurchase);
    setMaxBuy(ido.maxPurchase);
    setHardCap(ido.hardCap);
    setRate(ido.rate);
    setChainId(ido.chainId);
    setCapital(ido.capital);
  }, [setMinBuy, setMaxBuy, setCapital, setRate, ido]);

  useEffect(() => {
    if (!ido) {
      return;
    }
    let _remainingUntilHardCap = Infinity;
    if (ido.hardCap) {
      _remainingUntilHardCap = new BigNumber(ido.hardCap).minus(ido.capital);
    }

    const _remainingUntilMaxBuy = new BigNumber(ido.maxPurchase).minus(investedAmount || 0);
    const _remaning = BigNumber.min(_remainingUntilHardCap, _remainingUntilMaxBuy);

    setRemaning(+_remaning.toFixed(8, BigNumber.ROUND_UP));
  }, [ido, investedAmount]);

  const refreshBalance = useCallback(async () => {
    if (!account || !base || !nativeCoin) {
      return;
    }
    if (+chainId !== +network) {
      return;
    }
    const balancebnb = await getBalance("BNB", account);
    setBNBBalance(balancebnb || 0);
    if (base.symbol !== nativeCoin.symbol) {
      const tokenBalance = await fetchTokenBalance(base.address, account, network);
      setTokenBalance(tokenBalance);
    } else {
      setTokenBalance(balancebnb || 0);
    }
  }, [setBNBBalance, setTokenBalance, chainId, network, account, base, nativeCoin]);

  useEffect(() => {
    if (!connected) {
      return;
    }
    refreshBalance();
  }, [connected, base, refreshBalance, account, nativeCoin, chainId]);

  const getCorrectedBaseBase = useCallback(() => {
    let baseValue = inputValue;
    let initialValue = baseValue;
    if (base.symbol !== ido.baseSymbol) {
      baseValue = +new BigNumber(baseValue).div(relativeValidationPrice).toFixed(8, BigNumber.ROUND_UP);
    }

    if (+remaining < minBuy) {
      baseValue = minBuy * 1.0005;
      if (base.symbol !== ido.baseSymbol) {
        baseValue = +new BigNumber(baseValue).times(relativeValidationPrice).toFixed(8, BigNumber.ROUND_UP);
      }
      return baseValue;
    } else {
      return initialValue;
    }
  }, [base, ido, inputValue, relativeValidationPrice, remaining]);

  const swap = useCallback(async () => {
    if (!ido || !base) {
      return;
    }

    let tx;
    let baseValue = getCorrectedBaseBase();
    const config = {
      quoteValue: receiving,
      idoBaseValue,
      idoBaseCoin,
      baseValue,
      base,
      quoteSymbol: ido.ticker,
      relativeTokenPrice,
      ido,
      price: rate,
      quoteTokenIcon: ido.info.imagePath
    };
    setModalStatus({
      ...config,
      mode: "basic",
      action: async () => {
        tx = async () => await buyWithBase(baseValue);
        try {
          await executeTransaction({
            message: "Executing...",
            tx
          });
        } catch (error) {
          console.log(error);
        }
        setShowConfirm(false);
      }
    });
    if (base.symbol === ido.baseSymbol) {
      setModalStatus({
        ...config,
        mode: "basic",
        action: async () => {
          tx = async () => await buyWithBase(baseValue);
          try {
            await executeTransaction({
              message: "Executing...",
              tx
            });
          } catch (error) {
            console.log(error);
          }
          setShowConfirm(false);
        }
      });
    } else {
      setModalStatus({
        ...config,
        mode: "hop",
        action: async minAmount => {
          tx = async () => await swapAndBuy(base.address, baseValue, minAmount, base.address === nativeCoin.address);
          try {
            await executeTransaction({
              message: "Executing...",
              tx
            });
          } catch (error) {
            console.log(error);
          }
          setShowConfirm(false);
        }
      });
    }
    setShowConfirm(true);
  }, [
    setShowConfirm,
    executeTransaction,
    swapAndBuy,
    idoBaseValue,
    relativeTokenPrice,
    rate,
    setModalStatus,
    buyWithBase,
    getCorrectedBaseBase,
    receiving,
    base,
    ido,
    idoBaseCoin,
    nativeCoin
  ]);

  const checkValid = useCallback(async () => {
    if (!isWhitelisted) {
      setErrorMessage(`Your wallet is not whitelisted`);
      return false;
    }

    if (!ido || !base || !nativeCoin) {
      return false;
    }
    let baseValue = inputValue;
    const capitalValue = Number(new BigNumber(capital).toNumber(4, BigNumber.ROUND_DOWN));

    if (!isApproved) {
      setErrorMessage(null);
      return false;
    }

    if (baseValue > tokenBalance) {
      setErrorMessage(`Insufficent balance`);
      return false;
    }

    if (base.symbol === nativeCoin.symbol && bnbBalance - baseValue < 0.001 && baseValue > 0) {
      setErrorMessage(`Not enough balance left for gas.`);
      return false;
    }

    let _hardcap = hardCap;
    let _maxBuy = maxBuy;
    let _investedAmount = investedAmount;
    if (base.symbol !== ido.baseSymbol) {
      baseValue = +new BigNumber(baseValue).div(relativeValidationPrice).toFixed(8, BigNumber.ROUND_CEIL);
    }
    let _remaining = new BigNumber(remaining).toFixed(8, BigNumber.ROUND_CEIL);

    _remaining *= 1;
    _hardcap *= 1.01;
    _maxBuy *= 1.01;

    if (+_remaining > +minBuy && baseValue < minBuy) {
      setErrorMessage(`Minimum amount is ${minBuy} ${ido.baseSymbol}.`);
      return false;
    }

    if (bnbBalance < 0.005) {
      setErrorMessage(`Not enough balance left for gas.`);
      return false;
    }

    if (capitalValue > 0 && +_hardcap > 0 && +capitalValue >= +_hardcap) {
      setErrorMessage(`Hardcap was reached`);
      return false;
    }

    if (baseValue > +_remaining && baseValue > +minBuy) {
      setErrorMessage(`Only ${_remaining.toFixed(2)} ${ido.baseSymbol} left.`);
      return false;
    }

    if (_investedAmount + baseValue > _maxBuy) {
      setErrorMessage(`Maximum contribution limit reached.`);
      return false;
    }

    setErrorMessage(null);
    return !!inputValue > 0;
  }, [
    inputValue,
    bnbBalance,
    ido,
    tokenBalance,
    minBuy,
    maxBuy,
    capital,
    hardCap,
    isApproved,
    investedAmount,
    base,
    isWhitelisted,
    nativeCoin,
    relativeValidationPrice,
    remaining
  ]);

  useEffect(() => {
    checkValid().then(setIsValid);
  }, [
    base,
    ido,
    relativeNativePrice,
    idoBaseValue,
    receiving,
    chainId,
    network,
    account,
    nativeCoin,
    bnbBalance,
    tokenBalance,
    inputValue,
    checkValid
  ]);

  const handleCoinSelect = useCallback(
    async coin => {
      setBase(coin);
    },
    [setBase]
  );

  const calculateRelativeValue = useCallback(
    (totalCapital, value = 0) => {
      let relativeValue;
      let _rate = +rate;
      if (!relativeTokenPrice || !rate) {
        return 0;
      }

      if (ido.type === "fairlaunch") {
        if (ido.capital === 0) {
          return ido.amountForSale;
        } else {
          _rate = ido.amountForSale / totalCapital;
        }
      }

      if (base.symbol !== ido.baseSymbol) {
        relativeValue = ((value / relativeTokenPrice) * _rate).toFixed(8);
      } else {
        relativeValue = (value * _rate).toFixed(8);
      }

      return relativeValue;
    },
    [ido, base, rate, relativeTokenPrice]
  );

  const updateInput = useCallback(
    async (value = 0) => {
      if (!ido || !base || !idoValidationCoin) {
        return;
      }
      let totalCapital = capital;
      if (base.symbol !== ido.baseSymbol) {
        const baseSymbolValue = new BigNumber(value).div(relativeTokenPrice).toFixed(8);
        totalCapital += Number(baseSymbolValue);
        setIdoBaseValue(baseSymbolValue);
      } else {
        totalCapital += value;
        setIdoBaseValue(value);
      }

      if (base.symbol === idoValidationCoin.symbol) {
        setIdoNativeValue(value);
      }

      const relativeValue = calculateRelativeValue(totalCapital, value);
      setReceiving(relativeValue);
      setInputValue(value);
    },
    [
      setInputValue,
      base,
      ido,
      relativeTokenPrice,
      relativeValidationPrice,
      setIdoNativeValue,
      setReceiving,
      setIdoBaseValue,
      calculateRelativeValue,
      capital,
      idoValidationCoin
    ]
  );

  useEffect(() => {
    updateInput(inputValue);
  }, [base, relativeTokenPrice, relativeNativePrice, updateInput, nativeCoin]);

  const handleApprove = useCallback(async () => {
    let baseValue = inputValue;

    const tx = async () => await approve();

    await executeTransaction({
      message: "Approving",
      tx
    });

    setIsValid(await checkValid());
  }, [approve, executeTransaction, inputValue, checkValid]);

  const handleMax = useCallback(async () => {
    if (!base || !ido) {
      return;
    }
    let max = +remaining;
    if (base.symbol !== ido.baseSymbol) {
      max = +new BigNumber(max).times(relativeValidationPrice).toFixed(9, BigNumber.ROUND_CEIL);
    }
    setInputValue(roundToEight(max));
  }, [remaining, base, ido]);

  const handle25per = useCallback(async () => {
    if (!base || !ido) {
      return;
    }
    let value25 = +remaining * 0.25;
    if (base.symbol !== ido.baseSymbol) {
      value25 = +new BigNumber(value25).times(relativeValidationPrice);
    }
    setInputValue(value25);
  }, [remaining, base, ido]);

  const handle50per = useCallback(async () => {
    if (!base || !ido) {
      return;
    }
    let value50 = +remaining * 0.5;
    if (base.symbol !== ido.baseSymbol) {
      value50 = +new BigNumber(value50).times(relativeValidationPrice);
    }
    setInputValue(value50);
  }, [remaining, base, ido]);

  const handle75per = useCallback(async () => {
    if (!base || !ido) {
      return;
    }
    let value75 = +remaining * 0.75;
    if (base.symbol !== ido.baseSymbol) {
      value75 = +new BigNumber(value75).times(relativeValidationPrice);
    }
    setInputValue(roundToSix(value75));
  }, [remaining, base, ido]);

  const handle0per = useCallback(async () => {
    if (!base || !ido) {
      return;
    }
    let value0 = +remaining * 0;
    setInputValue(value0);
  }, [remaining, base, ido]);

  const ActionButton = () => {
    if (isApproved) {
      return (
        <Col xs={12}>
          <div className="d-flex mb-3 align-items-center">
            <Form.Check
              className="ido-check me-2"
              type="checkbox"
              onChange={() => {
                setChecked(!checked);
              }}
              checked={checked}
            />
            <small style={{ fontSize: 10 }} className="mt-2 text-left">
              I agree to the terms and conditions of this launchpad and fully understand the vesting schedule.
            </small>
          </div>
          <Button
            type="button"
            disabled={!checked || !connected || !isValid || !isCorrectNetwork || !!pendingTransaction || ido.status === 0}
            onClick={swap}
            className="mb-2 w-100"
          >
            Contribute
          </Button>
        </Col>
      );
    } else {
      return (
        <Button
          disabled={!connected || !isCorrectNetwork || !!pendingTransaction}
          onClick={handleApprove}
          className="mt-4 mb-2 w-100"
        >
          Approve
        </Button>
      );
    }
  };

  return (
    <>
      <Row className="align-items-center mt-4">
        <Col md={12} className="text-lg-start">
          {isWhitelisted}
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Form>
            <Row className="justify-content-center no-gutters">
              <Form.Group as={Col} md="12" className="input-control" controlId="validationFormik01">
                <IdoCoinSelectInput
                  defaultSelected={defaultCoin}
                  targetChainId={chainId}
                  value={inputValue}
                  selected={base}
                  handleMax={handleMax}
                  handle25per={handle25per}
                  handle50per={handle50per}
                  handle75per={handle75per}
                  handle0per={handle0per}
                  onInput={updateInput}
                  onSelect={handleCoinSelect}
                  receiving={receiving}
                  ido={ido}
                  base={base}
                  bnbBalance={bnbBalance}
                  tokenBalance={tokenBalance}
                />
              </Form.Group>

              <Form.Group as={Col} md="12" className="mt-1 mb-0">
                <ActionButton></ActionButton>
                {connected && isCorrectNetwork && errorMessage && <p className="mb-0 text-center">{errorMessage}</p>}
              </Form.Group>
            </Row>
          </Form>
        </Col>
      </Row>

      <Modal
        className="stake-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={!!showConfirm}
        onHide={handleClose}
      >
        <Modal.Body>
          <IdoConfirm {...modalStatus}></IdoConfirm>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default IdoSwap;
