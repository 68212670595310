import { useFormik } from "formik";
import React, { useCallback, useState, useEffect } from "react";
import { Col, FormControl, InputGroup, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { useDebounce } from "react-use";
import * as Yup from "yup";
import { fToken } from "../../utils/formatNumber";
import { useLiquidityProviders } from "../../utils/utils";
import RangeSlider from "./RangeSlider";
import RangeSliderListing from "./RangeSliderListing";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const TokenomicsFormValidation = (formData, branch, isFairLaunch, liquidRaiseMax) =>
  Yup.object().shape({
    amount_for_sale:
      branch !== "presale" &&
      isFairLaunch === true &&
      Yup.number()
        .typeError("Amount for sale must be a number and not empty")
        .test("amount for sale", "You have exceed Tokens", value => {
          return value <= liquidRaiseMax;
        })
        .required(),
    tokens_for_presale: Yup.number()
      .typeError("Tokens for sale must be a number")
      .test("supply", "Insufficient Tokens", value => {
        return value <= formData.summary.balance;
      }),
    // token_symbol: Yup.string().oneOf(["TOKEN", "MAX"]).default("TOKEN").required("token symbol is required"),
    // bal_range: Yup.number().typeError("balance range must be a number").required("balance range is required"),
    hard_cap:
      branch === "presale" &&
      Yup.number()
        .typeError("Hard capital must be a number")
        .required("Hard capital is required")
        .test("hard-cap", "Must be higher than the soft cap.", (value, ctx) => {
          return +value >= +ctx.parent.soft_cap;
        }),
    presale_rate:
      branch === "presale" &&
      Yup.number()
        .typeError("Presale rate must be a number")
        .required("Presale rate is required"),
    launch_price:
      branch === "presale" &&
      Yup.number()
        .typeError("Soft capital must be a number")
        .required("Launch price is required"),
    soft_cap: Yup.number()
      .typeError("Soft capital must be a number")
      .required("Soft capital is required")
      .test("soft-cap", "Must be lower than the total sale amount.", (value, ctx) => {
        return branch === "presale" ? value <= ctx.parent.hard_cap : true;
      }),
    percent_towards_liquidity: Yup.number()
      .typeError("Percent towards liquidity must be a number")
      .required(),
    adjust_listing_rate: Yup.number()
      .typeError("Adjust listing rate must be a number")
      .required(),
    min_contribution: Yup.number()
      .typeError("Minimum contribution must be a number")
      .required("Min contribution is required")
      .test("min-contrib", "Must be lower than the maximum contribution", (value, ctx) => {
        return value <= ctx.parent.max_contribution;
      })
      .test("Must be Postive value", "ERROR: The number must be greater than 0!", value => value > 0),
    max_contribution: Yup.number()
      .typeError("Maximum contribution must be a number")
      .required("Max contribution is required")
      .test("max-contrib", "Must be bigger than the maximum contribution", (value, ctx) => {
        return value >= ctx.parent.min_contribution;
      })
      .test("max-contrib", "Must lower than the hard cap", (value, ctx) => {
        return branch !== "presale" || value <= ctx.parent.hard_cap;
      }),

    start_date: Yup.mixed()
      .typeError("Start date must be a date")
      .required("Start date is required")
      .test(
        "is-date",
        "Start date needs to be at least 5 minutes from now",
        value => new Date(value).getTime() >= Date.now() + 1000 * 60 * 2
      ),
    end_date: Yup.mixed()
      .typeError("End date must be a date")
      .required("End date is required")
      .test("is-date", "End date is invalid", (value, ctx) => {
        return new Date(value).getTime() > new Date(ctx.parent.start_date).getTime();
      }),
    ito_liquidity: Yup.number()
      .typeError("Amount must be a number")
      .required("Plase specify the lock time in days")
      .min(30, "Minimum lock time is 30 days"),
    base_symbol: Yup.string().required("Please select the base token"),
    liquidity_provider: Yup.string().required("Liquidiy Provider is required")
  });

const Tokenomics = ({
  values,
  onNext,
  formData,
  tabEnabled,
  network,
  baseTokens,
  setBaseSymbolAndRate,
  branch,
  isFairLaunch,
  changeliquidityprovider
}) => {
  const [liquidtoggle, setLiquidToggle] = useState(false);
  const [liquidValue, setLiquidValue] = useState("");
  const [baseSymbolToggle, setBaseSymbolToggle] = useState(false);
  const [liquidRaiseMax, setLiquidRaiseMax] = useState();

  const liquidityProviders = useLiquidityProviders(network);
  const formik = useFormik({
    initialValues: values,
    validationSchema: TokenomicsFormValidation(formData, branch, isFairLaunch, liquidRaiseMax)
  });

  useDebounce(
    () => {
      let requiredAmount;

      if (branch === "presale") {
        const launchPrice =
          +formik.values.presale_rate - (formik.values.presale_rate * formik.values.adjust_listing_rate) / 100;
        requiredAmount =
          formik.values.presale_rate * formik.values.hard_cap +
          launchPrice * ((formik.values.percent_towards_liquidity * formik.values.hard_cap) / 100);

        if (isNaN(requiredAmount) || isNaN(launchPrice)) {
          return;
        }
        formik.handleChange({
          target: { value: launchPrice, name: "launch_price" }
        });
        formik.handleChange({
          target: { value: requiredAmount, name: "tokens_for_presale" }
        });
      } else {
        requiredAmount =
          Number(formik.values.amount_for_sale) +
          (+formik.values.percent_towards_liquidity * +formik.values.amount_for_sale) / 100;
        if (isNaN(requiredAmount)) {
          return;
        }
        formik.handleChange({
          target: {
            value: +requiredAmount.toFixed(+formData?.summary?.decimals),
            name: "tokens_for_presale"
          }
        });
      }
    },
    300,
    [formik?.values]
  );

  useEffect(() => {
    if (liquidityProviders) {
      formik.handleChange({
        target: {
          value: liquidityProviders[0].router,
          name: "liquidity_provider"
        }
      });
      setLiquidValue(liquidityProviders[0].name);
    }
  }, [liquidityProviders]);

  useEffect(() => {
    setBaseSymbolAndRate(formik?.values?.base_symbol, formik?.values?.presale_rate);
  }, [formik.values]);

  useEffect(() => {
    if (formik.values) {
      if (!(Object.keys(formik.touched).length === 0 && tabEnabled)) {
        if (Object.keys(formik.errors).length === 0 && Object.keys(formik.touched).length > 0) {
          onNext(3, true, "tokenomics", formik.values);
        } else {
          onNext(3, false, "tokenomics", formik.values);
        }
      } else {
        onNext(3, true, "tokenomics", formik.values);
      }
    }
  }, [formik.values, tabEnabled, formik.errors]);

  useEffect(() => {
    if (branch !== "presale" && formData && isFairLaunch === false) {
      const amount = formData?.summary?.supply / (1 + formik?.values?.percent_towards_liquidity / 100);
      formik.handleChange({
        target: { value: amount, name: "amount_for_sale" }
      });
    }
  }, [formData, formik?.values]);

  useEffect(() => {
    if (branch !== "presale" && formData && isFairLaunch === true) {
      const liquidityRaiseMaxAmount = formData?.summary?.supply / (1 + formik?.values?.percent_towards_liquidity / 100);
      setLiquidRaiseMax(liquidityRaiseMaxAmount);
    }
  }, [formData, formik?.values]);

  const val = useCallback(() => {
    const result = +formik.values.presale_rate - (formik.values.presale_rate * formik.values.adjust_listing_rate) / 100;
    return isNaN(result) ? 0 : result;
  }, [formik?.values.presale_rate, formik?.values?.adjust_listing_rate]);

  function handlepertowardsliquidInputChange(e) {
    const target = e.target.value;
    if (!isNaN(target) === true) {
      formik.handleChange({
        target: {
          name: "percent_towards_liquidity",
          value: target
        }
      });
    } else {
      formik.handleChange({
        target: {
          name: "percent_towards_liquidity",
          value: 50
        }
      });
    }
  }

  function handleblurpertowardsliquid(e) {
    const target = Math.floor(e.target.value);
    if (target > 49 && target < 101) {
      formik.handleChange({
        target: {
          name: "percent_towards_liquidity",
          value: target
        }
      });
    } else {
      formik.handleChange({
        target: {
          name: "percent_towards_liquidity",
          value: 50
        }
      });
    }
  }

  function handleAdjustListingInputChange(e) {
    const target = e.target.value;
    if (!isNaN(target) === true) {
      formik.handleChange({
        target: {
          name: "adjust_listing_rate",
          value: target
        }
      });
    } else {
      formik.handleChange({
        target: {
          name: "adjust_listing_rate",
          value: 0
        }
      });
    }
  }

  function handleBlurAdjustListing(e) {
    const target = Math.floor(e.target.value);
    if (target => 0 && target < 26) {
      formik.handleChange({
        target: {
          name: "adjust_listing_rate",
          value: target
        }
      });
    } else {
      formik.handleChange({
        target: {
          name: "adjust_listing_rate",
          value: 0
        }
      });
    }
  }

  return (
    <div className="tab-box presale-tokenomics">
      <div className="tab-box-inner tab-box-inner-lg">
        <div className="d-flex align-items-center justify-content-between">
          <h3 className="hpaycreatehead">Tokenomics</h3>
          <div className="d-flex flex-row align-items-center">
            <span className="me-3 connectwallettextpricinggold">Required:</span>
            <span className="popin-500-16">
              {fToken(formik.values.tokens_for_presale)}
              {formData.summary.symbol}
            </span>
          </div>
        </div>

        <h3>
          {formik.errors.tokens_for_presale && (
            <small className="hpadrequiredred" children={formik.errors.tokens_for_presale} />
          )}
        </h3>
        <hr className="mb-3 hpaycreate2line" />

        <Row>
          <Col md={6}>
            <div className="text-start radio-percent">
              <div className="mb-3 d-flex flex-row">
                <span className="hpadstep3label me-3">Platform</span>
                <OverlayTrigger
                  key="top"
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-top" className="Rubik-400-8 hpad-brand-holder2">
                      The exchange on which your token is being listed
                    </Tooltip>
                  }
                >
                  <img className="tooltipsizehpad" src={"/Caret.png"} alt="tooltip" />
                </OverlayTrigger>
              </div>

              {liquidityProviders && (
                <div className="hpadstep3dropdown d-flex flex-column w-100">
                  <div
                    className={`hpadstep3slt w-100 d-flex align-items-center ${
                      liquidtoggle ? "hpadstep3sltaddboard" : null
                    }`}
                    role="button"
                    onClick={() => {
                      setLiquidToggle(!liquidtoggle);
                    }}
                  >
                    <img className="hpadlabelbasesymbol me-3" src={`/${liquidValue}.svg`} />
                    <span>{liquidValue}</span>
                  </div>
                  {liquidtoggle && (
                    <div className="hpadstep3sltouter w-100">
                      <div className="hpadstep3sltinner w-100">
                        {liquidityProviders?.map((item, key) => (
                          <>
                            <div
                              className="d-flex flex-row align-items-center w-100"
                              role="button"
                              key={key}
                              onClick={() => {
                                setLiquidToggle(!liquidtoggle);
                                changeliquidityprovider({
                                  ...formData,
                                  tokenomics: {
                                    ...formData.tokenomics,
                                    liquidity_provider: item.router
                                  }
                                });
                                setLiquidValue(item.name);
                              }}
                            >
                              <img className="hpadlabelbasesymbol me-3" src={`/${item.name}.svg`} alt="" />
                              <span>{item.name}</span>
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </Col>
          <Col md={3}>
            <div className="text-start radio-percent">
              <div className="mb-3 d-flex flex-row">
                <span className="hpadstep3label me-lg-1 me-3">Liquidity Asset</span>
                <OverlayTrigger
                  key="top"
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-top" className="Rubik-400-8 hpad-brand-holder2">
                      The presale currency that you are using to raise funds and the users will be using to purchase
                      your token
                    </Tooltip>
                  }
                >
                  <img className="tooltipsizehpad" src={"/Caret.png"} alt="tooltip" />
                </OverlayTrigger>
              </div>

              <div className="hpadstep3dropdown d-flex flex-column w-100">
                <div
                  className={`hpadstep3slt w-100 d-flex align-items-center ${
                    baseSymbolToggle ? "hpadstep3sltaddboard" : null
                  }`}
                  role="button"
                  onClick={() => {
                    setBaseSymbolToggle(!baseSymbolToggle);
                  }}
                >
                  <img className="hpadlabelbasesymbol me-3" src={`/${formData.tokenomics.base_symbol}.svg`} />
                  <span>{formData.tokenomics.base_symbol}</span>
                </div>
                {baseSymbolToggle && (
                  <div className="hpadstep3sltouter w-100">
                    <div className="hpadstep3sltinner w-100">
                      {baseTokens?.map(item => (
                        <>
                          <div
                            className="d-flex flex-row align-items-center w-100"
                            role="button"
                            key={item?.address}
                            onClick={() => {
                              formik.handleChange({
                                target: {
                                  name: "base_symbol",
                                  value: item.symbol
                                }
                              });
                              setBaseSymbolToggle(!baseSymbolToggle);
                            }}
                          >
                            <img className="hpadlabelbasesymbol me-3" src={`/${item.symbol}.svg`} alt="" />
                            <span>{item.symbol}</span>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="text-start radio-percent">
              <div className="mb-3 d-flex flex-row">
                <span className="hpadstep3label me-3">Participants</span>
                <OverlayTrigger
                  key="top"
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-top" className="Rubik-400-8 hpad-brand-holder2">
                      A whitelist allows pre-approved wallet addresses to receive early access to purchase the presale.
                      The whitelist feature is turned on by default and it can be disabled at anytime
                    </Tooltip>
                  }
                >
                  <img className="tooltipsizehpad" src={"/Caret.png"} alt="tooltip" />
                </OverlayTrigger>
              </div>
              <div className="hpadstep3whitelistouter d-flex align-items-center justify-content-between">
                <span>Whitelist</span>
                <div className="hpadstep3whitelistbtn btn-primary" role="button" tabIndex={0}>
                  On
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col md={3} className="">
            <div className="text-start radio-percent">
              <div className="mb-3 d-flex flex-row">
                <span className="hpadstep3label me-3">Softcap</span>
              </div>
              <InputGroup className="form-hpay-input-group">
                <div className="d-flex">
                  <FormControl
                    value={formik.values.soft_cap}
                    name="soft_cap"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="form-hpay popin-500-16"
                  />
                  <InputGroup.Text className="border-0">
                    <div className="d-flex flex-row align-items-center">
                      <img
                        className="me-1 hpadlabelbasesymbol"
                        src={`/${formData.tokenomics.base_symbol}.svg`}
                        alt="base icon"
                      />
                      <span className="text-white">{formData.tokenomics.base_symbol}</span>
                    </div>
                  </InputGroup.Text>
                </div>
                {formik.errors.soft_cap && formik.touched.soft_cap && (
                  <small className="hpadrequiredred" children={formik.errors.soft_cap} />
                )}
              </InputGroup>
            </div>
          </Col>
          {branch === "fairlaunch" && (
            <Col md={3} className="">
              <div className="text-start radio-percent">
                <div className="mb-3 d-flex flex-row">
                  <span className="hpadstep3label me-3">Lock Liquidity for</span>
                  <OverlayTrigger
                    key="top"
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-top" className="Rubik-400-8 hpad-brand-holder2">
                        Locking liquidity makes the funds immovable until they are unlocked. The min locking period is
                        30 days
                      </Tooltip>
                    }
                  >
                    <img className="tooltipsizehpad" src={"/Caret.png"} alt="tooltip" />
                  </OverlayTrigger>
                </div>
                <InputGroup className="form-hpay-input-group">
                  <FormControl
                    value={formik.values.ito_liquidity}
                    name="ito_liquidity"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="form-hpay popin-500-16"
                  />
                  <InputGroup.Text className="border-0 customhpadstepdays">
                    <span className="text-primary-gradient">Days</span>
                  </InputGroup.Text>
                </InputGroup>
              </div>
              {formik.errors.ito_liquidity && formik.touched.ito_liquidity && (
                <small className="hpadrequiredred" children={formik.errors.ito_liquidity} />
              )}
            </Col>
          )}
          {branch === "presale" && (
            <Col md={3}>
              <div className="text-start radio-percent">
                <div className="mb-3 d-flex flex-row">
                  <span className="hpadstep3label me-3">Hardcap</span>
                </div>
                <InputGroup className="form-hpay-input-group d-block">
                  <div className="d-flex">
                    <FormControl
                      value={formik.values.hard_cap}
                      name="hard_cap"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="form-hpay popin-500-16"
                    />
                    <InputGroup.Text className="border-0">
                      <div className="d-flex flex-row align-items-center">
                        <img
                          className="me-1 hpadlabelbasesymbol"
                          src={`/${formData.tokenomics.base_symbol}.svg`}
                          alt="base icon"
                        />
                        <span className="text-white">{formData.tokenomics.base_symbol}</span>
                      </div>
                    </InputGroup.Text>
                  </div>
                  {formik.errors.hard_cap && formik.touched.hard_cap && (
                    <small className="hpadrequiredred" children={formik.errors.hard_cap} />
                  )}
                </InputGroup>
              </div>
            </Col>
          )}
          <Col md={3}>
            <div className="text-start radio-percent">
              <div className="mb-3 d-flex flex-row">
                <span className="hpadstep3label">Min Contribution</span>
              </div>
              <InputGroup className="form-hpay-input-group d-block">
                <div className="d-flex radio-percent">
                  <FormControl
                    name={"min_contribution"}
                    value={formik.values.min_contribution}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="form-hpay popin-500-16"
                  />
                  <InputGroup.Text className="border-0">
                    <div className="d-flex flex-row align-items-center">
                      <img
                        className="me-1 hpadlabelbasesymbol"
                        src={`/${formData.tokenomics.base_symbol}.svg`}
                        alt="base icon"
                      />
                      <span className="text-white">{formData.tokenomics.base_symbol}</span>
                    </div>
                  </InputGroup.Text>
                </div>
                {formik.errors.min_contribution && formik.touched.min_contribution && (
                  <small className="hpadrequiredred" children={formik.errors.min_contribution} />
                )}
              </InputGroup>
            </div>
          </Col>
          <Col md={3}>
            <div className="text-start radio-percent">
              <div className="mb-3 d-flex flex-row">
                <span className="hpadstep3label">Max Contribution</span>
              </div>
              <InputGroup className="form-hpay-input-group d-block">
                <div className="d-flex radio-percent">
                  <FormControl
                    name={"max_contribution"}
                    value={formik.values.max_contribution}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="form-hpay popin-500-16"
                  />
                  <InputGroup.Text className="border-0">
                    <div className="d-flex flex-row align-items-center">
                      <img
                        className="me-1 hpadlabelbasesymbol"
                        src={`/${formData.tokenomics.base_symbol}.svg`}
                        alt="base icon"
                      />
                      <span className="text-white">{formData.tokenomics.base_symbol}</span>
                    </div>
                  </InputGroup.Text>
                </div>
                {formik.errors.max_contribution && formik.touched.max_contribution && (
                  <small className="hpadrequiredred" children={formik.errors.max_contribution} />
                )}
              </InputGroup>
            </div>
          </Col>
        </Row>

        {branch === "presale" && (
          <Row className="mt-5">
            <Col md={6}>
              <div className="d-flex flex-row align-items-start justify-content-between">
                <div className="hpadrangerlabel mb-3 d-flex flex-row">
                  <span className="hpadstep3label me-3">Percentage Towards Liquidity</span>
                  <OverlayTrigger
                    key="top"
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-top" className="Rubik-400-8 hpad-brand-holder2">
                        The percentage of your total funds raised that will be sent to your liquidity
                      </Tooltip>
                    }
                  >
                    <img className="tooltipsizehpad" src={"/Caret.png"} alt="tooltip" />
                  </OverlayTrigger>
                </div>
                <div className="postioningrelative">
                  <input
                    className="hpadrangeinput form-hpay"
                    value={formData.tokenomics.percent_towards_liquidity}
                    onChange={handlepertowardsliquidInputChange}
                    onBlur={handleblurpertowardsliquid}
                  />
                  <span className="postioningabsolute inputrangetopfieldpos">&#37;</span>
                </div>
              </div>
              <div className="d-flex flex-row align-items-start justify-content-between">
                <div className="d-flex flex-column align-items-start justify-content-start">
                  <div
                    className={`hpadrangelabelline ${
                      formik.values.percent_towards_liquidity === 50 ? "active" : "null"
                    }`}
                  ></div>
                  <span
                    className={`hpadrangelabeltext ${
                      formik.values.percent_towards_liquidity === 50 ? "active" : "null"
                    }`}
                  >
                    50%
                  </span>
                </div>
                <div className="d-flex flex-column align-items-start justify-content-start">
                  <div
                    className={`hpadrangelabelline ${
                      formik.values.percent_towards_liquidity === 60 ? "active" : "null"
                    }`}
                  ></div>
                  <span
                    className={`hpadrangelabeltext ${
                      formik.values.percent_towards_liquidity === 60 ? "active" : "null"
                    }`}
                  >
                    60%
                  </span>
                </div>
                <div className="d-flex flex-column align-items-start justify-content-start">
                  <div
                    className={`hpadrangelabelline ${
                      formik.values.percent_towards_liquidity === 70 ? "active" : "null"
                    }`}
                  ></div>
                  <span
                    className={`hpadrangelabeltext ${
                      formik.values.percent_towards_liquidity === 70 ? "active" : "null"
                    }`}
                  >
                    70%
                  </span>
                </div>
                <div className="d-flex flex-column align-items-start justify-content-start">
                  <div
                    className={`hpadrangelabelline ${
                      formik.values.percent_towards_liquidity === 80 ? "active" : "null"
                    }`}
                  ></div>
                  <span
                    className={`hpadrangelabeltext ${
                      formik.values.percent_towards_liquidity === 80 ? "active" : "null"
                    }`}
                  >
                    80%
                  </span>
                </div>
                <div className="d-flex flex-column align-items-start justify-content-start">
                  <div
                    className={`hpadrangelabelline ${
                      formik.values.percent_towards_liquidity === 90 ? "active" : "null"
                    }`}
                  ></div>
                  <span
                    className={`hpadrangelabeltext ${
                      formik.values.percent_towards_liquidity === 90 ? "active" : "null"
                    }`}
                  >
                    90%
                  </span>
                </div>
                <div className="d-flex flex-column align-items-start justify-content-start">
                  <div
                    className={`hpadrangelabelline ${
                      formik.values.percent_towards_liquidity === 100 ? "active" : "null"
                    }`}
                  ></div>
                  <span
                    className={`hpadrangelabeltext ${
                      formik.values.percent_towards_liquidity === 100 ? "active" : "null"
                    }`}
                  >
                    100%
                  </span>
                </div>
              </div>
              <div className="mt-4">
                <RangeSlider
                  min={45}
                  name="percent_towards_liquidity"
                  value={[formik.values.percent_towards_liquidity]}
                  onChange={value =>
                    formik.handleChange({
                      target: {
                        name: "percent_towards_liquidity",
                        value: value[0]
                      }
                    })
                  }
                />
                {formik.errors.percent_towards_liquidity && formik.touched.percent_towards_liquidity && (
                  <small className="hpadrequiredred" children={formik.errors.percent_towards_liquidity} />
                )}
              </div>
            </Col>

            <Col md={6}>
              <div className="mx-3">
                <div className="d-flex flex-row flex-lg-column">
                  <span className="connectwallettextpricinggold">Presale Rate:</span>
                  <div className="ms-md-0 ms-3 mt-1 d-flex flex-row">
                    <div className="d-flex flex-column justify-content-start">
                      <span className="mb-4 connectwallettextpricingwhite d-flex flex-row">
                        1 {formData.tokenomics.base_symbol}
                        <img
                          loading="lazy"
                          className="ms-1 hpadsysmberpresalerate hpadlabelbasesymbol"
                          src={`/${formData.tokenomics.base_symbol}.svg`}
                          alt="pancakeswap cake icon"
                        />
                      </span>
                      <span className="connectwallettextpricingwhite">1 {formData.summary.symbol}</span>
                    </div>
                    <div className="ms-2 d-flex flex-column align-items-center">
                      <span className="mb-4 connectwallettextpricinggold hpaystep3presalesrateequal">=</span>
                      <span className="connectwallettextpricinggold hpaystep3presalesrateequal">=</span>
                    </div>
                    <div className="ms-2 d-flex flex-column justify-content-start">
                      <span className="mb-4 connectwallettextpricingwhite">
                        {fToken(formik.values.presale_rate)}
                        {formData.summary.symbol}
                      </span>
                      <span className="connectwallettextpricingwhite">
                        {fToken(+formik.values.presale_rate > 0 ? 1 / +formik.values.presale_rate : 0)}
                        &#160;
                        {formData.tokenomics.base_symbol}
                        <img
                          loading="lazy"
                          className="ms-1 hpadsysmberpresalerate hpadlabelbasesymbol"
                          src={`/${formData.tokenomics.base_symbol}.svg`}
                          alt="pancakeswap cake icon"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        )}
        {isFairLaunch && (
          <Row className="mt-5">
            <Col md={6}>
              <div className="d-flex flex-row align-items-start justify-content-between">
                <div className="hpadrangerlabel mb-3 d-flex flex-row">
                  <span className="hpadstep3label me-3">Percentage Towards Liquidity</span>
                  <OverlayTrigger
                    key="top"
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-top" className="Rubik-400-8 hpad-brand-holder2">
                        The percentage of your total funds raised that will be sent to your liquidity
                      </Tooltip>
                    }
                  >
                    <img className="tooltipsizehpad" src={"/Caret.png"} alt="tooltip" />
                  </OverlayTrigger>
                </div>
                <div className="hpadrangeinput form-hpay d-flex flex-row align-items-center">
                  <span>{formik.values.percent_towards_liquidity}</span>
                  <span>&#37;</span>
                </div>
              </div>
              <div className="d-flex flex-row align-items-start justify-content-between">
                <div className="d-flex flex-column align-items-start justify-content-start">
                  <div
                    className={`hpadrangelabelline ${
                      formik.values.percent_towards_liquidity === 50 ? "active" : "null"
                    }`}
                  ></div>
                  <span
                    className={`hpadrangelabeltext ${
                      formik.values.percent_towards_liquidity === 50 ? "active" : "null"
                    }`}
                  >
                    50%
                  </span>
                </div>
                <div className="d-flex flex-column align-items-start justify-content-start">
                  <div
                    className={`hpadrangelabelline ${
                      formik.values.percent_towards_liquidity === 60 ? "active" : "null"
                    }`}
                  ></div>
                  <span
                    className={`hpadrangelabeltext ${
                      formik.values.percent_towards_liquidity === 60 ? "active" : "null"
                    }`}
                  >
                    60%
                  </span>
                </div>
                <div className="d-flex flex-column align-items-start justify-content-start">
                  <div
                    className={`hpadrangelabelline ${
                      formik.values.percent_towards_liquidity === 70 ? "active" : "null"
                    }`}
                  ></div>
                  <span
                    className={`hpadrangelabeltext ${
                      formik.values.percent_towards_liquidity === 70 ? "active" : "null"
                    }`}
                  >
                    70%
                  </span>
                </div>
                <div className="d-flex flex-column align-items-start justify-content-start">
                  <div
                    className={`hpadrangelabelline ${
                      formik.values.percent_towards_liquidity === 80 ? "active" : "null"
                    }`}
                  ></div>
                  <span
                    className={`hpadrangelabeltext ${
                      formik.values.percent_towards_liquidity === 80 ? "active" : "null"
                    }`}
                  >
                    80%
                  </span>
                </div>
                <div className="d-flex flex-column align-items-start justify-content-start">
                  <div
                    className={`hpadrangelabelline ${
                      formik.values.percent_towards_liquidity === 90 ? "active" : "null"
                    }`}
                  ></div>
                  <span
                    className={`hpadrangelabeltext ${
                      formik.values.percent_towards_liquidity === 90 ? "active" : "null"
                    }`}
                  >
                    90%
                  </span>
                </div>
                <div className="d-flex flex-column align-items-start justify-content-start">
                  <div
                    className={`hpadrangelabelline ${
                      formik.values.percent_towards_liquidity === 100 ? "active" : "null"
                    }`}
                  ></div>
                  <span
                    className={`hpadrangelabeltext ${
                      formik.values.percent_towards_liquidity === 100 ? "active" : "null"
                    }`}
                  >
                    100%
                  </span>
                </div>
              </div>
              <div className="mt-4">
                <RangeSlider
                  min={45}
                  name="percent_towards_liquidity"
                  value={[formik.values.percent_towards_liquidity || 50]}
                  onChange={value =>
                    formik.handleChange({
                      target: {
                        name: "percent_towards_liquidity",
                        value: value[0]
                      }
                    })
                  }
                />
                {formik.errors.percent_towards_liquidity && formik.touched.percent_towards_liquidity && (
                  <small className="hpadrequiredred" children={formik.errors.percent_towards_liquidity} />
                )}
              </div>
            </Col>

            <Col md={6}>
              <div className="text-start">
                <div className="d-flex flex-row align-items-start justify-content-between">
                  <div className="hpadrangerlabel mb-3 d-flex flex-row">
                    <span className="hpadstep3label me-3">Amount for Sale</span>
                  </div>
                  <div className="d-flex flex-row">
                    <span className="hpadbalancelabel gold me-1">Balance:</span>
                    <span className="hpadbalancelabel">
                      {fToken(
                        +formik.values.presale_rate -
                          (formik.values.presale_rate * formik.values.adjust_listing_rate) / 100
                      )}
                      {formData.summary.symbol}
                    </span>
                  </div>
                </div>
                <FormControl
                  placeholder="Amount for sale*"
                  name="amount_for_sale"
                  value={formik.values.amount_for_sale}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="form-hpay popin-500-16"
                />
                {formik.errors.amount_for_sale && (
                  <small className="hpadrequiredred" children={formik.errors.amount_for_sale} />
                )}
              </div>
            </Col>
          </Row>
        )}

        {branch === "fairlaunch" && isFairLaunch === false && (
          <Row className="mt-5 mb-3">
            <Col md={6}>
              <div className="d-flex flex-row justify-content-end hpadcreatestep3inputliquard mb-2">
                <span className="hpadcreatestep3inputliquardgold ms-1">Balance:</span>
                <span>{formData.summary.supply}</span>
                <span>{formData.summary.symbol}</span>
              </div>
              <div className="mb-2">
                <p className="hpadcreatestep3inputliquardwarning">
                  For fair launch, the full token supply will be allocated
                </p>
              </div>
              <div className="d-flex flex-row align-items-start justify-content-between">
                <div className="hpadrangerlabel mb-3 d-flex flex-row">
                  <span className="hpadstep3label me-3">Percentage Towards Liquidity</span>
                  <OverlayTrigger
                    key="top"
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-top" className="Rubik-400-8 hpad-brand-holder2">
                        The percentage of your total funds raised that will be sent to your liquidity
                      </Tooltip>
                    }
                  >
                    <img className="tooltipsizehpad" src={"/Caret.png"} alt="tooltip" />
                  </OverlayTrigger>
                </div>
                <div className="hpadrangeinput form-hpay d-flex flex-row align-items-center">
                  <span>100</span>
                  <span>&#37;</span>
                </div>
              </div>
              <div className="d-flex flex-row align-items-start justify-content-between">
                <div className="d-flex flex-column align-items-start justify-content-start">
                  <div className="hpadrangelabelline"></div>
                  <span className="hpadrangelabeltext">50%</span>
                </div>
                <div className="d-flex flex-column align-items-start justify-content-start">
                  <div className="hpadrangelabelline"></div>
                  <span className="hpadrangelabeltext">60%</span>
                </div>
                <div className="d-flex flex-column align-items-start justify-content-start">
                  <div className="hpadrangelabelline"></div>
                  <span className="hpadrangelabeltext">70%</span>
                </div>
                <div className="d-flex flex-column align-items-start justify-content-start">
                  <div className="hpadrangelabelline"></div>
                  <span className="hpadrangelabeltext">80%</span>
                </div>
                <div className="d-flex flex-column align-items-start justify-content-start">
                  <div className="hpadrangelabelline"></div>
                  <span className="hpadrangelabeltext">90%</span>
                </div>
                <div className="d-flex flex-column align-items-start justify-content-start">
                  <div className="hpadrangelabelline active"></div>
                  <span className="hpadrangelabeltext active">100%</span>
                </div>
              </div>
              <div className="mt-4">
                <div className="position-relative range-strip w-100">
                  <div className="position-relative range-strip-stat-saticdot"></div>
                </div>
              </div>
            </Col>

            <Col md={6}>
              <div className="text-start radio-percent">
                <div className="mb-3 d-flex flex-row">
                  <span className="hpadstep3label me-3">Start Time</span>
                </div>
                <InputGroup className="form-hpay-input-group d-block form-control form-hpay">
                  <ReactDatePicker
                    className="dateandTimePicker"
                    timeInputLabel="Time "
                    minDate={new Date()}
                    minTime={new Date()}
                    dateFormat="MM/dd/yyyy h:mm aa"
                    showTimeInput
                    selected={formik.values.start_date}
                    onChange={date =>
                      formik.handleChange({
                        target: { name: "start_date", value: date }
                      })
                    }
                  />
                </InputGroup>
                {formik.errors.start_date && <small className="hpadrequiredred" children={formik.errors.start_date} />}
              </div>
              <div className="text-start radio-percent">
                <div className="mb-3 d-flex flex-row">
                  <span className="hpadstep3label me-3">End Time</span>
                </div>
                <InputGroup className="form-hpay-input-group d-block form-control form-hpay">
                  <ReactDatePicker
                    timeInputLabel="Time "
                    className="dateandTimePicker"
                    minDate={new Date()}
                    minTime={new Date()}
                    dateFormat="MM/dd/yyyy h:mm aa"
                    showTimeInput
                    selected={formik.values.end_date}
                    onChange={date =>
                      formik.handleChange({
                        target: { name: "end_date", value: date }
                      })
                    }
                  />
                </InputGroup>
                {formik.errors.end_date && <small className="hpadrequiredred" children={formik.errors.end_date} />}
              </div>
            </Col>
          </Row>
        )}

        {branch === "presale" && (
          <Row className="mt-5">
            <Col md={6}>
              <div className="d-flex flex-row align-items-start justify-content-between">
                <div className="hpadrangerlabel mb-3 d-flex flex-row">
                  <span className="hpadstep3label me-3">Listing Rate</span>
                  <OverlayTrigger
                    key="top"
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-top" className="Rubik-400-8 hpad-brand-holder2">
                        Listing Rate
                      </Tooltip>
                    }
                  >
                    <img className="tooltipsizehpad" src={"/Caret.png"} alt="tooltip" />
                  </OverlayTrigger>
                </div>
                <div className="postioningrelative">
                  <span className="postioningabsolute inputrangetopfieldpos2">&#45;</span>
                  <input
                    className="hpadrangeinput2 form-hpay"
                    value={formik.values.adjust_listing_rate}
                    onChange={handleAdjustListingInputChange}
                    onBlur={handleBlurAdjustListing}
                  />
                  <span className="postioningabsolute inputrangetopfieldpos">&#37;</span>
                </div>
                {/* <div className="hpadrangeinput form-hpay d-flex flex-row align-items-center">
                  <span>&#45;</span>
                  <span>{formik.values.adjust_listing_rate}</span>
                  <span>&#37;</span>
                </div> */}
              </div>
              <div className="mt-3 d-flex flex-row align-items-start justify-content-between">
                <div className="d-flex flex-column align-items-start justify-content-start">
                  <div
                    className={`hpadrangelabelline ${formik.values.adjust_listing_rate === 0 ? "active" : "null"}`}
                  ></div>
                  <span className={`hpadrangelabeltext ${formik.values.adjust_listing_rate === 0 ? "active" : "null"}`}>
                    0%
                  </span>
                </div>
                <div className="ms-2 d-flex flex-column align-items-start justify-content-start">
                  <div
                    className={`hpadrangelabelline ${formik.values.adjust_listing_rate === 5 ? "active" : "null"}`}
                  ></div>
                  <span className={`hpadrangelabeltext ${formik.values.adjust_listing_rate === 5 ? "active" : "null"}`}>
                    -5%
                  </span>
                </div>
                <div className="ms-2 d-flex flex-column align-items-start justify-content-start">
                  <div
                    className={`hpadrangelabelline ${formik.values.adjust_listing_rate === 10 ? "active" : "null"}`}
                  ></div>
                  <span
                    className={`hpadrangelabeltext ${formik.values.adjust_listing_rate === 10 ? "active" : "null"}`}
                  >
                    -10%
                  </span>
                </div>
                <div className="d-flex flex-column align-items-start justify-content-start">
                  <div
                    className={`hpadrangelabelline ${formik.values.adjust_listing_rate === 15 ? "active" : "null"}`}
                  ></div>
                  <span
                    className={`hpadrangelabeltext ${formik.values.adjust_listing_rate === 15 ? "active" : "null"}`}
                  >
                    -15%
                  </span>
                </div>
                <div className="d-flex flex-column align-items-start justify-content-start">
                  <div
                    className={`hpadrangelabelline ${formik.values.adjust_listing_rate === 20 ? "active" : "null"}`}
                  ></div>
                  <span
                    className={`hpadrangelabeltext ${formik.values.adjust_listing_rate === 20 ? "active" : "null"}`}
                  >
                    -20%
                  </span>
                </div>
                <div className="d-flex flex-column align-items-start justify-content-start">
                  <div
                    className={`hpadrangelabelline ${formik.values.adjust_listing_rate === 25 ? "active" : "null"}`}
                  ></div>
                  <span
                    className={`hpadrangelabeltext ${formik.values.adjust_listing_rate === 25 ? "active" : "null"}`}
                  >
                    -25%
                  </span>
                </div>
              </div>
              <div className="mt-4">
                <RangeSliderListing
                  min={0}
                  name="adjust_listing_rate"
                  value={[formik.values.adjust_listing_rate || 0]}
                  onChange={value =>
                    formik.handleChange({
                      target: { name: "adjust_listing_rate", value: value[0] }
                    })
                  }
                />
                {formik.errors.adjust_listing_rate && formik.touched.adjust_listing_rate && (
                  <small className="hpadrequiredred" children={formik.errors.adjust_listing_rate} />
                )}
              </div>
            </Col>
            <Col md={6}>
              <div className="mx-3">
                <div className="d-flex flex-row flex-lg-column">
                  <span className="connectwallettextpricinggold">Listing Rate:</span>
                  <div className="ms-md-0 ms-3 mt-1 d-flex flex-row">
                    <div className="d-flex flex-column justify-content-start">
                      <span className="mb-4 connectwallettextpricingwhite d-flex flex-row">
                        1 {formik.values.base_symbol}
                        <img
                          loading="lazy"
                          className="ms-1 hpadsysmberpresalerate hpadlabelbasesymbol"
                          src={`/${formData.tokenomics.base_symbol}.svg`}
                          alt="pancakeswap cake icon"
                        />
                      </span>
                      <span className="connectwallettextpricingwhite">1 {formData.summary.symbol}</span>
                    </div>
                    <div className="ms-2 d-flex flex-column align-items-center">
                      <span className="mb-4 connectwallettextpricinggold hpaystep3presalesrateequal">=</span>
                      <span className="connectwallettextpricinggold hpaystep3presalesrateequal">=</span>
                    </div>
                    <div className="ms-2 d-flex flex-column justify-content-start">
                      <span className="mb-4 connectwallettextpricingwhite">
                        {fToken(
                          +formik.values.presale_rate -
                            (formik.values.presale_rate * formik.values.adjust_listing_rate) / 100
                        )}
                        {formData.summary.symbol}
                      </span>
                      <span className="connectwallettextpricingwhite">
                        {fToken(val() > 0 ? 1 / val() : 0)}
                        &#160;
                        {formik.values.base_symbol}
                        <img
                          loading="lazy"
                          className="ms-1 hpadsysmberpresalerate hpadlabelbasesymbol"
                          src={`/${formData.tokenomics.base_symbol}.svg`}
                          alt="pancakeswap cake icon"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        )}

        {branch === "presale" && (
          <Row className="mt-5">
            <Col md={6} className="mb-3">
              <div className="text-start radio-percent">
                <div className="mb-3 d-flex flex-row">
                  <span className="hpadstep3label me-3">Lock Liquidity for</span>
                  <OverlayTrigger
                    key="top"
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-top" className="Rubik-400-8 hpad-brand-holder2">
                        Locking liquidity makes the funds immovable until they are unlocked. The min locking period is
                        30 days
                      </Tooltip>
                    }
                  >
                    <img className="tooltipsizehpad" src={"/Caret.png"} alt="tooltip" />
                  </OverlayTrigger>
                </div>
                <InputGroup className="form-hpay-input-group">
                  <FormControl
                    value={formik.values.ito_liquidity}
                    name="ito_liquidity"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Itto liquidity loc"
                    className="form-hpay popin-500-16"
                  />
                  <InputGroup.Text className="border-0 customhpadstepdays">
                    <span className="text-primary-gradient">Days</span>
                  </InputGroup.Text>
                </InputGroup>
              </div>
              {formik.errors.ito_liquidity && formik.touched.ito_liquidity && (
                <>
                  <span></span>
                  <small className="hpadrequiredred" children={formik.errors.ito_liquidity} />
                </>
              )}
            </Col>
            <Col md={6} className="mb-3">
              {branch === "presale" && (
                <div className="text-start">
                  <div className="d-flex flex-row align-items-start justify-content-between">
                    <div className="hpadrangerlabel mb-3 d-flex flex-row">
                      <span className="hpadstep3label me-3">Presale Rate</span>
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-top" className="Rubik-400-8 hpad-brand-holder2">
                            The selling price of your token during the presale
                          </Tooltip>
                        }
                      >
                        <img className="tooltipsizehpad" src={"/Caret.png"} alt="tooltip" />
                      </OverlayTrigger>
                    </div>
                    <div className="d-flex flex-row">
                      <span className="hpadbalancelabel gold me-1">Balance:</span>
                      <span className="hpadbalancelabel">
                        {fToken(formData.summary.balance)}
                        &nbsp;
                        {formData.summary.symbol}
                      </span>
                    </div>
                  </div>
                  <FormControl
                    placeholder="Presale Rate*"
                    name={"presale_rate"}
                    value={formik.values.presale_rate}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="form-hpay popin-500-16"
                  />
                  {formik.errors.presale_rate && formik.touched.presale_rate && (
                    <small className="hpadrequiredred" children={formik.errors.presale_rate} />
                  )}
                </div>
              )}
            </Col>
          </Row>
        )}
        {branch === "presale" && (
          <Row className="mt-3">
            <Col md={6} className="mb-3 ">
              <div className="text-start radio-percent">
                <div className="mb-3 d-flex flex-row">
                  <span className="hpadstep3label me-3">Start Time</span>
                </div>
                <InputGroup className="form-hpay-input-group d-block form-control form-hpay">
                  <ReactDatePicker
                    className="dateandTimePicker"
                    timeInputLabel="Time "
                    minDate={new Date()}
                    minTime={new Date()}
                    dateFormat="MM/dd/yyyy h:mm aa"
                    showTimeInput
                    selected={formik.values.start_date}
                    onChange={date =>
                      formik.handleChange({
                        target: { name: "start_date", value: date }
                      })
                    }
                  />
                </InputGroup>
                {formik.errors.start_date && <small className="hpadrequiredred" children={formik.errors.start_date} />}
              </div>
            </Col>
            <Col md={6} className="mb-3">
              <div className="text-start radio-percent">
                <div className="mb-3 d-flex flex-row">
                  <span className="hpadstep3label me-3">End Time</span>
                </div>
                <InputGroup className="form-hpay-input-group d-block form-control form-hpay">
                  <ReactDatePicker
                    timeInputLabel="Time "
                    className="dateandTimePicker"
                    minDate={new Date()}
                    minTime={new Date()}
                    dateFormat="MM/dd/yyyy h:mm aa"
                    showTimeInput
                    selected={formik.values.end_date}
                    onChange={date =>
                      formik.handleChange({
                        target: { name: "end_date", value: date }
                      })
                    }
                  />
                </InputGroup>
                {formik.errors.end_date && <small className="hpadrequiredred" children={formik.errors.end_date} />}
              </div>
            </Col>
          </Row>
        )}
        {isFairLaunch && (
          <Row className="mt-3">
            <Col md={6} className="mb-3 ">
              <div className="text-start radio-percent">
                <div className="mb-3 d-flex flex-row">
                  <span className="hpadstep3label me-3">Start Time</span>
                </div>
                <InputGroup className="form-hpay-input-group d-block form-control form-hpay">
                  <ReactDatePicker
                    className="dateandTimePicker"
                    timeInputLabel="Time "
                    minDate={new Date()}
                    minTime={new Date()}
                    dateFormat="MM/dd/yyyy h:mm aa"
                    showTimeInput
                    selected={formik.values.start_date}
                    onChange={date =>
                      formik.handleChange({
                        target: { name: "start_date", value: date }
                      })
                    }
                  />
                </InputGroup>
                {formik.errors.start_date && <small className="hpadrequiredred" children={formik.errors.start_date} />}
              </div>
            </Col>
            <Col md={6} className="mb-3">
              <div className="text-start radio-percent">
                <div className="mb-3 d-flex flex-row">
                  <span className="hpadstep3label me-3">End Time</span>
                </div>
                <InputGroup className="form-hpay-input-group d-block form-control form-hpay">
                  <ReactDatePicker
                    timeInputLabel="Time "
                    className="dateandTimePicker"
                    minDate={new Date()}
                    minTime={new Date()}
                    dateFormat="MM/dd/yyyy h:mm aa"
                    showTimeInput
                    selected={formik.values.end_date}
                    onChange={date =>
                      formik.handleChange({
                        target: { name: "end_date", value: date }
                      })
                    }
                  />
                </InputGroup>
                {formik.errors.end_date && <small className="hpadrequiredred" children={formik.errors.end_date} />}
              </div>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default Tokenomics;
