import React, { useContext, useEffect, useState } from "react";
import NetworkSelectModal from "./NetworkSelectModal";
import networkIcon from "../../images/chain-icon";
import { useIdoNetworks } from "../../web3/idos";
import NetworkContext from "../../context/network-context";

function NetworkPicker({ network, onChangeNetwork, onToggleValidNetwork }) {
  const [networkModalShow, setNetworkModalShow] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState();
  const networks = useIdoNetworks();
  const { connected, connect } = useContext(NetworkContext);

  useEffect(() => {
    if (!network) {
      return;
    }

    const selected = networks.find(element => {
      return element.chainId == network;
    });

    onChangeNetwork(selected);
    onToggleValidNetwork(selected ? true : false);
    setSelectedNetwork(selected);
  }, [networks, network]);

  return (
    <>
      <div
        role="button"
        className="coin-select-input-wrapper"
        onClick={() => {
          if (connected) {
            setNetworkModalShow(true);
          } else {
            connect();
          }
        }}
      >
        <div className="coin-select-input justify-content-between">
          {connected ? null : <small className="connectwallettextwarning">Please connect your wallet</small>}
          {connected && (
            <>
            <h5 className="mb-0 connectwallettextwarning">
              <img
                style={{ width: "20px", height: "20px", marginRight: "0.75rem" }}
                src={
                  (selectedNetwork && networkIcon[selectedNetwork.shortName]) ||
                  "data:image/gif;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA="
                }
                alt="chain icon"
                onError={event => (event.target.style.display = "none")}
              />
              {selectedNetwork && selectedNetwork.chainName}
            </h5>
            <span className="connectwallettextpricing me-5">
              {selectedNetwork === undefined  ? null : (<>
                {selectedNetwork.chainName.includes("Testnet") ?
                <> 
                0.1 {selectedNetwork === undefined  ? null : (<>{selectedNetwork.currency}</>) } Test Token
                </>
                : 
                <>
                1 {selectedNetwork === undefined  ? null : (<>{selectedNetwork.currency}</>) } + 1% Liquidity Raised
                </>}
                </>) } 
            </span>
            </>
          )}
          <div>
            {connected ? <span className="connectwallettextwarning grey me-2">
            {selectedNetwork === undefined  ? null : (<>{selectedNetwork.chainName.includes("Testnet") ? "Testnet" : "Mainnet"}</>) } 
              </span> : null}
            <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1 1L7 7L13 1"
                stroke="white"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M1 1L7 7L13 1"
                stroke="url(#paint0_linear_521_1583)"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <defs>
                <linearGradient
                  id="paint0_linear_521_1583"
                  x1="0.557046"
                  y1="-6.07143"
                  x2="19.2764"
                  y2="2.60286"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FFE986" stopOpacity="0.88"></stop>
                  <stop offset="0.670219" stopColor="#BC7E20"></stop>
                  <stop offset="0.670319" stopColor="#BC7D1F"></stop>
                  <stop offset="1" stopColor="#A74D01"></stop>
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
      </div>
      <NetworkSelectModal
        networks={networks}
        defaultSelected={network}
        show={networkModalShow}
        onHide={() => setNetworkModalShow(false)}
        onSelect={onChangeNetwork}
      />
    </>
  );
}

export default NetworkPicker;
