import React from 'react';
import * as moment from "moment";
import { Card, Col, Row, Button } from 'react-bootstrap';

function VestingSchedule({ schedule, handleClose }) {

    return (
        <div className="vesting-schedule">
            <Card>
                <Card.Body>
                    <Row style={{ marginBottom: '1rem' }} >
                        <Col md={12} className=" text-center ">
                            <h3 className="text-white ">Vesting Schedule</h3>
                            <small>Tokens claims will be avaliable according to the following vesting schedule</small>
                        </Col>
                    </Row>
                    {
                        schedule.map((item, index) => <Row key={index} style={{ marginBottom: '1rem' }} className="justify-content-center" >
                            <Col xs={6}>
                                <small>Unlock Date</small>
                                <h5>{moment(item.date).format('DD MMM YYYY')}</h5>
                            </Col>
                            <Col xs={6} className="text-end">
                                <small>Claim</small>
                                <h5 className="text-primary-gradient">{item.unlock} %</h5>
                            </Col>
                        </Row>)
                    }
                    <Button onClick={handleClose}>Close</Button>
                </Card.Body >
            </Card >
        </div >
    );
}

export default VestingSchedule;
