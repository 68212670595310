import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { useCurrentIdo } from "../../../state/idos";
import NetworkContext from "context/network-context";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import Transak from "components/transak";
import { fToken } from "utils/formatNumber";
import { useGetPresaleRate } from "web3/fair-launch";

const statusMapping = {
  0: "Starting Soon",
  1: "Running",
  2: "Ended",
  3: "Ended"
};

function IdoStats({ address, idoInfo, investedAmount, refund, userBalance }) {
  const { connected, isCorrectNetwork, pendingTransaction, executeTransaction } = useContext(NetworkContext);
  const [saleSupply, setSaleSupply] = useState(0);
  const [, setLiquidity] = useState(0);
  const [idoStartTime, setIdoStartTime] = useState();
  const [idoEndTime, setIdoEndTime] = useState();
  const [status, setStatus] = useState();
  const [price, setPrice] = useState();
  const [launchPrice, setLaunchPrice] = useState();
  const [pair, setPair] = useState();
  const [claimed, setClaimed] = useState(false);
  const [lockupPer, setLockupPer] = useState();
  const [transk, setTransk] = useState(false);
  const [presaleRate, setpresaleRate] = useState();
  const fetchFairLaunchPresaleRate = useGetPresaleRate();

  useEffect(() => {
    if (!idoInfo) {
      return;
    }
    const startDate = moment(idoInfo.startTime).format("DD MMM YYYY, HH:mm ");
    const anStartDate = moment(idoInfo.startTime);
    const endDate = moment(idoInfo.endTime).format("DD MMM YYYY, HH:mm ");
    const lockUpDate = moment(anStartDate).add(30, "days");
    const todayDate = moment(Date.now());
    setStatus(statusMapping[idoInfo.status]);
    setIdoStartTime(startDate);
    setIdoEndTime(endDate);
    if (moment("2010-10-20").isAfter("2010-10-19")) {
      setLockupPer(100);
    } else {
      setLockupPer(Math.round((idoInfo.lockTime - todayDate.diff(lockUpDate, "days")) / idoInfo.lockTime));
    }
  }, [setStatus, setIdoStartTime, setIdoEndTime, idoInfo]);

  useEffect(() => {
    if (!idoInfo) {
      return;
    }
    const fetchBCFairData = async () => {
      let data = await fetchFairLaunchPresaleRate(idoInfo.presaleContractAddress);
      setpresaleRate(data);
    };
    fetchBCFairData().catch(console.error);
  }, [idoInfo]);

  useEffect(() => {
    if (!idoInfo) {
      return;
    }
    setSaleSupply(idoInfo.tokenSupply);
    setLiquidity(idoInfo.hardCap * idoInfo.liquidity * idoInfo.launchPrice);
    if (idoInfo.inversedRate) {
      setPrice(+(1 / +idoInfo.rate).toFixed(8));
      setLaunchPrice(+(1 / +idoInfo.launchPrice).toFixed(8));
      setPair(`${idoInfo.baseSymbol}/${idoInfo.ticker}`);
    } else {
      setPrice(+idoInfo.rate);
      setLaunchPrice(+idoInfo.launchPrice);
      setPair(`${idoInfo.ticker}/${idoInfo.baseSymbol}`);
    }
  }, [setSaleSupply, setLiquidity, idoInfo]);

  const handleRefund = async () => {
    const tx = async () => refund();

    await executeTransaction({
      message: "Refunding contribution",
      tx
    }).then(() => {
      setClaimed(true);
    });
  };
  const handleTransk = () => {
    setTransk(true);

    setTimeout(() => {
      setTransk(false);
    }, 100);
  };
  return (
    <>
      <Row className="idoheaderbackgroundheader idoheaderbackgroundheadertop pt-3 w-100 popin-500-16 mx-0 pb-5">
        <Col md={6}>
          <div className="d-flex flex-row w-100 mb-4">
            <div className="idocontentfirstcol"></div>
            <div className="d-flex align-items-center justify-content-between w-100">
              <span className=" grey3-text">Whitelisted</span>
              <span>{idoInfo?.whitelisted && idoInfo?.whitelisted ? <>Yes</> : <>No</>}</span>
            </div>
          </div>

          <div className="d-flex flex-row w-100 mb-4">
            <div className="idocontentfirstcol"></div>
            <div className="d-flex align-items-center justify-content-between w-100">
              <span className=" grey3-text">Start Date</span>
              <span>{idoStartTime}</span>
            </div>
          </div>
          <div className="d-flex flex-row w-100 mb-4">
            <div className="idocontentfirstcol"></div>
            <div className="d-flex align-items-center justify-content-between w-100">
              <span className=" grey3-text">End Date</span>
              <span>{idoEndTime}</span>
            </div>
          </div>
          <div className="d-flex flex-row w-100 mb-4">
            <div className="idocontentfirstcol">
              <img src={"/lock.svg"} alt="lock" />
            </div>
            <div className="d-flex align-items-center justify-content-between w-100">
              <span className=" grey3-text">Liquidity %</span>
              <span>{idoInfo?.liquidity} %</span>
            </div>
          </div>
          <div className="d-flex flex-row w-100 mb-4">
            <div className="idocontentfirstcol">
              <img src={"/lock.svg"} alt="lock" />
            </div>
            <div className="d-flex align-items-center justify-content-between w-100">
              <span className="grey3-text">Lockup Time</span>
              <div className="d-flex align-items-center justify-content-center">
                <div className="idocontentpercentage me-1">
                  <CircularProgressbar
                    value={lockupPer}
                    background
                    strokeWidth={50}
                    styles={buildStyles({
                      backgroundColor: "#333333",
                      strokeLinecap: "butt",
                      pathColor: "#04E762",
                      trailColor: "transparent"
                    })}
                  />
                </div>
                <span className="popin-400-12 green-text me-1">{lockupPer}%</span>
                <span>{idoInfo?.lockTime} Days</span>
              </div>
            </div>
          </div>
          <div className="d-flex flex-row w-100 mb-4">
            <div className="idocontentfirstcol">
              <img src={"/lock.svg"} alt="lock" />
            </div>
            <div className="d-flex align-items-center justify-content-between w-100">
              <span className=" grey3-text">Vesting Info</span>
              <div className="d-flex align-items-center">
                {idoInfo?.tokenAddress.substring(0, 4)}...
                {idoInfo?.tokenAddress.substr(idoInfo?.tokenAddress.length - 4)}
              </div>
            </div>
          </div>
        </Col>

        <Col md={6}>
          <div className="d-flex flex-row w-100 mb-4">
            <div className="idocontentfirstcol"></div>
            <div className="d-flex align-items-center justify-content-between w-100">
              <span className=" grey3-text">My Contributions</span>
              <div>
                <span>
                  {fToken(+investedAmount)} {idoInfo?.baseSymbol}
                </span>
                <img className="ms-1" src={`/idoicon/woBG/${idoInfo?.baseSymbol}.svg`} alt="baseSymbol" />
              </div>
            </div>
          </div>
          <div className="d-flex flex-row w-100 mb-4">
            <div className="idocontentfirstcol"></div>
            <div className="d-flex align-items-center justify-content-between w-100">
              <span className=" grey3-text">Softcap</span>
              <div>
                <span>
                  {idoInfo?.softCap} {idoInfo?.baseSymbol}
                </span>
                <img className="ms-1" src={`/idoicon/woBG/${idoInfo?.baseSymbol}.svg`} alt="bnb" />
              </div>
            </div>
          </div>
          <div className="d-flex flex-row w-100 mb-4">
            <div className="idocontentfirstcol"></div>
            <div className="d-flex align-items-center justify-content-between w-100">
              <span className=" grey3-text">Min Contribution</span>
              <div>
                <span>
                  {idoInfo?.minPurchase} {idoInfo?.baseSymbol}
                </span>
                <img className="ms-1" src={`/idoicon/woBG/${idoInfo?.baseSymbol}.svg`} alt="bnb" />
              </div>
            </div>
          </div>
          <div className="d-flex flex-row w-100 mb-4">
            <div className="idocontentfirstcol"></div>
            <div className="d-flex align-items-center justify-content-between w-100">
              <span className=" grey3-text">Max Contribution</span>
              <div>
                <span>
                  {idoInfo?.maxPurchase} {idoInfo?.baseSymbol}
                </span>
                <img className="ms-1" src={`/idoicon/woBG/${idoInfo?.baseSymbol}.svg`} alt="bnb" />
              </div>
            </div>
          </div>
          <div className="d-flex flex-row w-100 mb-4">
            <div className="idocontentfirstcol"></div>
            <div className="d-flex align-items-center justify-content-between w-100">
              <span className=" grey3-text">Presale Rate</span>
              <div>
                <span>
                  {presaleRate} {idoInfo?.ticker} = 1 {idoInfo?.baseSymbol}
                </span>
                <img className="ms-1" src={`/idoicon/woBG/${idoInfo?.baseSymbol}.svg`} alt="bnb" />
              </div>
            </div>
          </div>
          <div className="d-flex flex-row w-100 mb-4">
            <div className="idocontentfirstcol"></div>
            <div className="d-flex align-items-center justify-content-between w-100">
              <span className=" grey3-text">Listing Rate</span>
              <div>
                <span>
                  {presaleRate} {idoInfo?.ticker} = 1 {idoInfo?.baseSymbol}
                </span>
                <img className="ms-1" src={`/idoicon/woBG/${idoInfo?.baseSymbol}.svg`} alt="bnb" />
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div className="hpaycreate3line"></div>
      <Row className="idoheaderbackgroundheaderbottom2 w-100 mx-0">
        <Col md={12} className="d-flex flex-row justify-content-evenly">
          <div role="button" onClick={handleTransk} className="d-flex align-items-center">
            <img className="me-1" src={"/icons/credit-card-as.svg"} alt="credit card" />
            <span className="popin-600-12 gold2-text">Fiat 2 Crypto</span>
          </div>
          <div
            className="d-flex align-items-center"
            role="button"
            onClick={handleRefund}
            disabled={!connected || !isCorrectNetwork || !!pendingTransaction || claimed}
          >
            <img className="me-1" src={"/icons/refund.svg"} alt="refund" />
            <span className="popin-600-12 gold2-text">Refund</span>
          </div>
          <a href="https://t.me/hedgepay" rel="noopener noreferrer" className="d-flex align-items-center">
            <img className="me-1" src={"/icons/support.svg"} alt="support" />
            <span className="popin-600-12 gold2-text">Support</span>
          </a>
        </Col>
      </Row>
      {transk && <Transak />}
    </>
  );
}

export default IdoStats;
