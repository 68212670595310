import React, { useCallback, useState, useEffect } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import networkIcon from "../../images/chain-icon";

const NetworkItemSelect = ({ chainName, shortName, onSelect }) => (
  <div
    role="button"
    onKeyPress={onSelect}
    onClick={() => onSelect()}
    className="networkselectingbtn d-flex justify-content-start align-items-center"
  >
    <img
      style={{ width: "32px", height: "32px", marginRight: "0.75rem" }}
      src={networkIcon[shortName]}
      alt="chain icon"
    />
    <h5 className="networkselectingbtnhead">{chainName}</h5>
  </div>
);

function NetworkSelectModal({ networks, defaultSelected, ...props }) {
  const [networkType, setNetworkType] = useState("Mainnet");
  const handleSelect = useCallback(
    value => {
      if (props.onSelect) {
        props.onSelect(value);
      }
      props.onHide();
    },
    [props.onSelect]
  );

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body style={{ maxHeight: 415 }}>
        <Container>
          <Row>
            <Col className="networktype-outer">
              <button
                className={`networktypebtn left ${
                  networkType === "Mainnet" ? "active" : "inactive"
                } d-flex justify-content-center align-items-center`}
                onClick={() => setNetworkType("Mainnet")}
              >
                Mainnet
              </button>
            </Col>
            <Col className="networktype-outer">
              <button
                className={`networktypebtn right ${
                  networkType === "Testnet" ? "active" : "inactive"
                } d-flex justify-content-center align-items-center`}
                onClick={() => setNetworkType("Testnet")}
              >
                Testnet
              </button>
            </Col>
          </Row>
          <Row className="mt-3">
            <div className="removerowpadding" style={{ overflowX: "scroll", maxHeight: 420 }}>
              {networkType === "Testnet" ? (
                <>
                  {networks &&
                    networks
                      .filter(network => network.chainName.includes("Testnet"))
                      .map(network => (
                        <NetworkItemSelect {...network} onSelect={() => handleSelect(network)} key={network.chainName} />
                      ))}
                </>
              ) : null}

              {networkType === "Mainnet" ? (
                <>
                  {networks &&
                    networks
                      .filter(network => !network.chainName.includes("Testnet"))
                      .map(network => (
                        <NetworkItemSelect {...network} onSelect={() => handleSelect(network)} key={network.chainName} />
                      ))}
                </>
              ) : null}
            </div>
          </Row>
          <Row>
            <Col>
              <div className="d-flex justify-content-center align-items-center">
                <span className="othernetworktext1">Looking for other</span>
                <span className="ms-1 othernetworktext2">EVM Chain?</span>
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default NetworkSelectModal;
