import React, { useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Col, Row } from "react-bootstrap";
import { fToken } from "../../utils/formatNumber";

const InformationFormValidation = Yup.object().shape({
  file: Yup.mixed()
    .required()
    .test("file-size", "The file cannot be > 1MB", value => {
      if (!value) {
        return true;
      }
      return value.size <= 1 * 1000 * 1000;
    }),
  project_description: Yup.string().required("Project description is required"),
  website: Yup.string().required("Website url is required"), //.matches(/^((http|https):\/\/)(.)*$/, "Invalid website url"),
  userexplorer: Yup.string().required("User Explorer is required"), //.matches(/^((http|https):\/\/)(.)*$/, "Invalid website url"),
  whitepaper: Yup.string().required("whitepaper is Required"), //.matches(/^((http|https):\/\/)(.)*$/, "Invalid website url"),
  telegram: Yup.string().required("Telegram url is required"), //.matches(/^((http|https):\/\/)(t.me|telegram.me)\/(.)*$/, "Invalid telegram url"),
  twitter: Yup.string().required("Twitter url is required"), //.matches(/^((http|https):\/\/)(twitter.com)\/(.)*$/, "Invalid twitter url"),
  discord: Yup.string().nullable(true), //.matches(/^((http|https):\/\/)(discord.com)\/(.)*$/, "Invalid discord url"),
  instagram: Yup.string().nullable(true), //.matches(/^((http|https):\/\/)(instagram.com)\/(.)*$/, "Invalid instagram url"),
  facebook: Yup.string().nullable(true), //.matches(/^((http|https):\/\/)(facebook.com)\/(.)*$/, "Invalid facebook url"),
  medium: Yup.string().nullable(true), //.matches(/^((http|https):\/\/)(medium.com)\/(.)*$/, "Invalid medium url"),
  github: Yup.string().nullable(true), //.matches(/^((http|https):\/\/)(github.com)\/(.)*$/, "Invalid github url"),
  linktree: Yup.string().nullable(true), //.matches(/^((http|https):\/\/)(github.com)\/(.)*$/, "Invalid github url"),
  youtube: Yup.string().nullable(true), //.matches(/^((http|https):\/\/)(github.com)\/(.)*$/, "Invalid github url"),
  tiktok: Yup.string().nullable(true), //.matches(/^((http|https):\/\/)(github.com)\/(.)*$/, "Invalid github url"),
  reddit: Yup.string().nullable(true), //.matches(/^((http|https):\/\/)(github.com)\/(.)*$/, "Invalid github url"),
  linkedin: Yup.string().nullable(true), //.matches(/^((http|https):\/\/)(github.com)\/(.)*$/, "Invalid github url"),
  adsvideo: Yup.string().nullable(true), //.matches(/^((http|https):\/\/)(github.com)\/(.)*$/, "Invalid github url"),
  kycurl: Yup.string().nullable(true),
  auditurl: Yup.string().nullable(true),
  // kycurl: Yup.string().matches(/^((http|https):\/\/)(hedgepay.org|certik.com|analytixaudit.com|hashex.org|freshcoins.io|expelee.com|auditrate.tech|audits.quillhash.com|cyberscope.io|rugdoc.io|cognitos.io)\/(.)*$/, "we accept KYC url from HedgePay,Certik,Analytix Audit,HashEx,Freshcoins,expelee,Audit Rate Tech,Quill Audit,Cyberscope,RUGDOC,RugFreeCoins,ShellBoxes,InterFi,cfg Ninja,Cognitos"),
  // auditurl: Yup.string().nullable(true).matches(/^((http|https):\/\/)(hedgepay.org|certik.com|analytixaudit.com|hashex.org|freshcoins.io|expelee.com|auditrate.tech|audits.quillhash.com|cyberscope.io|rugdoc.io|rugfreecoins.com|shellboxes.com|interfi.network|cfg.ninja|cognitos.io)\/(.)*$/, "we accept Audit url from HedgePay,Certik,Analytix Audit,HashEx,Freshcoins,expelee,Audit Rate Tech,Quill Audit,Cyberscope,RUGDOC,RugFreeCoins,ShellBoxes,InterFi,cfg Ninja, Cognitos")
});

const Information = ({ values, onNext, network, formData, tabEnabled }) => {
  const formik = useFormik({
    initialValues: values,
    validationSchema: InformationFormValidation
  });

  useEffect(() => {
    if (!(Object.keys(formik.touched).length === 0 && tabEnabled)) {
      if (Object.keys(formik.errors).length === 0 && Object.keys(formik.touched).length > 0) {
        onNext(4, true, "information", formik.values);
      } else {
        onNext(4, false);
      }
    } else {
      onNext(3, true, "tokenomics", formik.values);
    }
  }, [formik.values, formik.errors, tabEnabled]);


  return (
    <div className="tab-box">
      <div className="tab-box-inner tab-box-inner-lg">
        <div className="d-flex align-items-center justify-content-between">
          <h3 className="hpaycreatehead">Information</h3>
          <div className="d-flex flex-row align-items-center">
            <span className="me-3 connectwallettextpricinggold">Required:</span>
            <span className="connectwallettextpricingwhite">
              {fToken(formData.tokenomics.tokens_for_presale)} {formData.summary.symbol}
            </span>
          </div>
        </div>

        <hr className="mb-3 hpaycreate2line" />
        
        <Row className="mt-5 mb-3 justify-content-center">
          <Col md="auto" className="mb-3 text-center">
            <input
              type="file"
              onChange={({ target: { name, files } }) => formik.handleChange({ target: { name, value: files[0] } })}
              multiple={false}
              onBlur={formik.handleBlur}
              name="file"
              accept="image/*"
              id="upload"
              hidden
            />
            <label htmlFor="upload" className="mainupload_image2 mx-auto mb-0">
              <div className="upload-image2">
                {formik.values.file ? (
                  <img src={URL.createObjectURL(formik.values.file)} alt="upload" />
                ) : (
                  <div className="imageholdertexthpadstep4">
                    <span className="inneruploadspan">
                      Upload Image <br />
                      200x200px
                    </span>
                    <span className="hpadarequiredredstar subrequiredhpad">*</span>
                  </div>
                )}
              </div>

              {formik.errors.file && <small className="hpadrequiredred" children={formik.errors.file} />}
            </label>
          </Col>

          <Col className="mb-3">
            <fieldset className="hpadcreatetextfield">
              <textarea
                id="some-id"
                value={formik.values.project_description}
                name="project_description"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                rows="4"
                className="form-control form-hpay step4hpadtextarea hpadcreatetextfield__input"
                required
              />
              <label className="hpadcreatetextfield__label hpadcreatetextfield__label--required">
                Project Description
              </label>
            </fieldset>
            {formik.errors.project_description && formik.touched.project_description && (
              <small className="hpadrequiredred" children={formik.errors.project_description} />
            )}
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <span className="connectwallettextpricinggold">Social</span>
          </Col>
        </Row>

        <Row md={6}>
          <Col md={6}>
            <fieldset className="hpadcreateinputfield">
              <input
                type="text"
                id="hpad-telegram"
                value={formik.values.telegram}
                name="telegram"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control form-hpay hpadcreateinputfield__input hpadcreatestep4b hpadcreatestep4inputborderradiustop"
                required
              />
              <label className="hpadcreateinputfield__label hpadcreateinputfield__label--required">
                Telegram
              </label>
              <img className="hpadcreateinputfield__image2" src={"/hpadcreateicon/telegram.svg"} alt="website" />
              {formik.errors.telegram && formik.touched.telegram && (
                <small className="hpadrequiredred" children={formik.errors.telegram} />
              )}
            </fieldset>
          </Col>
          <Col md={6}>
            <fieldset className="hpadcreateinputfield">
              <input
                type="text"
                id="hpad-twitter"
                value={formik.values.twitter}
                name="twitter"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control form-hpay hpadcreateinputfield__input hpadcreatestep4b hpadcreatestep4inputborderradiustop"
                required
              />
              <label className="hpadcreateinputfield__label hpadcreateinputfield__label--required">
                Twitter
              </label>
              <img className="hpadcreateinputfield__image2" src={"/hpadcreateicon/twitter.svg"} alt="twitter" />
              {formik.errors.twitter && formik.touched.twitter && (
                <small className="hpadrequiredred" children={formik.errors.twitter} />
              )}
            </fieldset>
          </Col>
          <Col md={6}>
            <fieldset className="hpadcreateinputfield">
              <input
                type="text"
                id="hpad-intragram"
                value={formik.values.instagram}
                name="instagram"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control form-hpay hpadcreateinputfield__input hpadcreatestep4b hpadcreatestep4inputborderradiusmed"
                placeholder="Instagram"
              />
              <img className="hpadcreateinputfield__image2" src={"/hpadcreateicon/intragram.svg"} alt="website" />
              {formik.errors.instagram && formik.touched.instagram && (
                <small className="hpadrequiredred" children={formik.errors.instagram} />
              )}
            </fieldset>
          </Col>
          <Col md={6}>
            <fieldset className="hpadcreateinputfield">
              <input
                type="text"
                id="hpad-facebook"
                value={formik.values.facebook}
                name="facebook"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control form-hpay hpadcreateinputfield__input hpadcreatestep4b hpadcreatestep4inputborderradiusmed"
                placeholder="Facebook"
              />
              <img className="hpadcreateinputfield__image2" src={"/hpadcreateicon/facebook.svg"} alt="facebook" />
              {formik.errors.facebook && formik.touched.facebook && (
                <small className="hpadrequiredred" children={formik.errors.facebook} />
              )}
            </fieldset>
          </Col>
          <Col md={6}>
            <fieldset className="hpadcreateinputfield">
              <input
                type="text"
                id="hpad-github"
                value={formik.values.github}
                name="github"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control form-hpay hpadcreateinputfield__input hpadcreatestep4b hpadcreatestep4inputborderradiusmed"
                placeholder="Github"
              />
              <img className="hpadcreateinputfield__image" src={"/hpadcreateicon/github.svg"} alt="github" />
              {formik.errors.github && formik.touched.github && (
                <small className="hpadrequiredred" children={formik.errors.github} />
              )}
            </fieldset>
          </Col>
          <Col md={6}>
            <fieldset className="hpadcreateinputfield">
              <input
                type="text"
                id="hpad-discord"
                value={formik.values.discord}
                name="discord"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control form-hpay hpadcreateinputfield__input hpadcreatestep4b hpadcreatestep4inputborderradiusmed"
                placeholder="Discord"
              />
              <img className="hpadcreateinputfield__image" src={"/hpadcreateicon/discord.svg"} alt="discord" />
              {formik.errors.discord && formik.touched.discord && (
                <small className="hpadrequiredred" children={formik.errors.discord} />
              )}
            </fieldset>
          </Col>
          <Col md={6}>
            <fieldset className="hpadcreateinputfield">
              <input
                type="text"
                id="hpad-linktree"
                value={formik.values.linktree}
                name="linktree"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control form-hpay hpadcreateinputfield__input hpadcreatestep4b hpadcreatestep4inputborderradiusmed"
                placeholder="Linktree"
              />
              <img className="hpadcreateinputfield__image" src={"/hpadcreateicon/linktree.svg"} alt="discord" />
              {formik.errors.linktree && formik.touched.linktree && (
                <small className="hpadrequiredred" children={formik.errors.linktree} />
              )}
            </fieldset>
          </Col>
          <Col md={6}>
            <fieldset className="hpadcreateinputfield">
              <input
                type="text"
                id="hpad-youtube"
                value={formik.values.youtube}
                name="youtube"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control form-hpay hpadcreateinputfield__input hpadcreatestep4b hpadcreatestep4inputborderradiusmed"
                placeholder="YouTube"
              />
              <img className="hpadcreateinputfield__image2" src={"/hpadcreateicon/adsvideo.svg"} alt="youtube" />
              {formik.errors.youtube && formik.touched.youtube && (
                <small className="hpadrequiredred" children={formik.errors.youtube} />
              )}
            </fieldset>
          </Col>
          <Col md={6}>
            <fieldset className="hpadcreateinputfield">
              <input
                type="text"
                id="hpad-TikTok"
                value={formik.values.tiktok}
                name="tiktok"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control form-hpay hpadcreateinputfield__input hpadcreatestep4b hpadcreatestep4inputborderradiusmed"
                placeholder="TikTok"
              />
              <img className="hpadcreateinputfield__image2" src={"/hpadcreateicon/tiktok.svg"} alt="TikTok" />
              {formik.errors.tiktok && formik.touched.tiktok && (
                <small className="hpadrequiredred" children={formik.errors.tiktok} />
              )}
            </fieldset>
          </Col>
          <Col md={6}>
            <fieldset className="hpadcreateinputfield">
              <input
                type="text"
                id="hpad-Reddit"
                value={formik.values.reddit}
                name="reddit"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control form-hpay hpadcreateinputfield__input hpadcreatestep4b hpadcreatestep4inputborderradiusmed"
                placeholder="Reddit"
              />
              <img className="hpadcreateinputfield__image" src={"/hpadcreateicon/reddit.svg"} alt="Reddit" />
              {formik.errors.reddit && formik.touched.reddit && (
                <small className="hpadrequiredred" children={formik.errors.reddit} />
              )}
            </fieldset>
          </Col>
          <Col md={6}>
            <fieldset className="hpadcreateinputfield">
              <input
                type="text"
                id="hpad-linkedin"
                value={formik.values.linkedin}
                name="linkedin"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control form-hpay hpadcreateinputfield__input hpadcreatestep4inputborderradiusbtm"
                placeholder="LinkedIn"
              />
              <img className="hpadcreateinputfield__image" src={"/hpadcreateicon/LinkedIn.svg"} alt="LinkedIn" />
              {formik.errors.linkedin && formik.touched.linkedin && (
                <small className="hpadrequiredred" children={formik.errors.linkedin} />
              )}
            </fieldset>
          </Col>
          <Col md={6}>
            <fieldset className="hpadcreateinputfield">
              <input
                type="text"
                id="hpad-Medium"
                value={formik.values.medium}
                name="medium"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control form-hpay hpadcreateinputfield__input hpadcreatestep4inputborderradiusbtm"
                placeholder="Medium"
              />
              <img className="hpadcreateinputfield__image2" src={"/hpadcreateicon/medium.svg"} alt="Medium" />
              {formik.errors.medium && formik.touched.medium && (
                <small className="hpadrequiredred" children={formik.errors.medium} />
              )}
            </fieldset>
          </Col>
        </Row>

        <Row className="mt-4 mb-2">
          <Col>
            <span className="connectwallettextpricinggold">Main Info</span>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <fieldset className="hpadcreateinputfield">
              <input
                type="text"
                id="hpadwebsite"
                value={formik.values.website}
                name="website"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control form-hpay hpadcreateinputfield__input hpadcreatestep4b hpadcreatestep4inputborderradiustop"
                required
              />
              <label className="hpadcreateinputfield__label hpadcreateinputfield__label--required">
                Website
              </label>
              <img className="hpadcreateinputfield__image2" src={"/hpadcreateicon/website.svg"} alt="website" />
            </fieldset>
            {formik.errors.website && formik.touched.website && (
              <small className="hpadrequiredred" children={formik.errors.website} />
            )}
          </Col>
          <Col md={6}>
            <fieldset className="hpadcreateinputfield">
              <input
                type="text"
                id="hpad-Explorer"
                value={formik.values.userexplorer}
                name="userexplorer"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control form-hpay hpadcreateinputfield__input hpadcreatestep4b hpadcreatestep4inputborderradiustop"
                required
              />
              <label className="hpadcreateinputfield__label hpadcreateinputfield__label--required">
                Explorer link
              </label>
              <img className="hpadcreateinputfield__image2" src={"/hpadcreateicon/explorerlink.svg"} alt="explorerlink" />
            </fieldset>
            {formik.errors.userexplorer && formik.touched.userexplorer && (
              <small className="hpadrequiredred" children={formik.errors.userexplorer} />
            )}
          </Col>
          <Col md={6}>
            <fieldset className="hpadcreateinputfield">
              <input
                type="text"
                id="hpad-whitepaper"
                value={formik.values.whitepaper}
                name="whitepaper"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control form-hpay hpadcreateinputfield__input hpadcreatestep4b hpadcreatestep4inputborderradiusmed"
                required
              />
              <label className="hpadcreateinputfield__label hpadcreateinputfield__label--required">
              whitepaper URL
              </label>
              <img className="hpadcreateinputfield__image" src={"/hpadcreateicon/whitepaper.svg"} alt="whitepaper" />
            </fieldset>
            {formik.errors.whitepaper && formik.touched.whitepaper && (
              <small className="hpadrequiredred" children={formik.errors.whitepaper} />
            )}
          </Col>

          <Col md={6}>
            <fieldset className="hpadcreateinputfield">
              <input
                type="text"
                id="hpad-Audit"
                value={formik.values.auditurl}
                name="auditurl"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control form-hpay hpadcreateinputfield__input hpadcreatestep4b hpadcreatestep4inputborderradiusmed"
                placeholder="Audit URL"
              />
              <img className="hpadcreateinputfield__image" src={"/hpadcreateicon/audit.svg"} alt="audit" />
              {formik.errors.auditurl && formik.touched.auditurl && (
                <small className="hpadrequiredred" children={formik.errors.auditurl} />
              )}
            </fieldset>
          </Col>

          <Col md={6}>
            <fieldset className="hpadcreateinputfield">
              <input
                type="text"
                id="hpad-KYC"
                value={formik.values.kycurl}
                name="kycurl"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control form-hpay hpadcreateinputfield__input hpadcreatestep4inputborderradiusbtm"
                placeholder="KYC URL"
              />
              <img className="hpadcreateinputfield__image" src={"/hpadcreateicon/KYC.svg"} alt="KYC" />
              {formik.errors.kycurl && formik.touched.kycurl && (
                <small className="hpadrequiredred" children={formik.errors.kycurl} />
              )}
            </fieldset>
          </Col>

          <Col md={6}>
            <fieldset className="hpadcreateinputfield">
              <input
                type="text"
                id="hpad-adsvideo"
                value={formik.values.adsvideo}
                name="adsvideo"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control form-hpay hpadcreateinputfield__input hpadcreatestep4inputborderradiusbtm"
                placeholder="Ads Video URL"
              />
              <img className="hpadcreateinputfield__image2" src={"/hpadcreateicon/adsvideo.svg"} alt="adsvideo" />
              {formik.errors.adsvideo && formik.touched.adsvideo && (
                <small className="hpadrequiredred" children={formik.errors.adsvideo} />
              )}
            </fieldset>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Information;
