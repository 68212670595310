import React, { useContext, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import NetworkContext from "../../context/network-context";
import networkIcon from "../../images/chain-icon";
import { networks } from "../../web3/web3";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const Summary = ({ nextButtonValidate, getValue, formData, tokenomics, selectedNetwork, type, tab, isFairLaunch }) => {
  const { pendingTransaction, connected, network } = useContext(NetworkContext);

  const [validNetwork, setValidNetwork] = useState(true);
  const [noBalance, setNoBalance] = useState(false);

  // useEffect(() => {
  //   const requiredAmount =
  //     Number(tokenomics.amount_for_sale) + (+tokenomics.percent_towards_liquidity * +tokenomics.amount_for_sale) / 100;
  //   if (isNaN(requiredAmount) || requiredAmount === 0) {
  //     setIsFairLaunch(false);
  //     return;
  //   }

  //   setIsFairLaunch(formData.supply === requiredAmount);
  // }, [formData?.values, tokenomics]);

  const nextTab = () => {
    getValue();
  };

  useEffect(() => {
    if (formData.summary.balance >= 0) {
      setNoBalance(true);
    }
    setValidNetwork(+selectedNetwork?.chainId === +network);
  }, [network, setValidNetwork, selectedNetwork, formData.summary.balance]);

  return (
    <div className="tab-box summary-box">
      <div className="tab-box-inner">
        <h4 className="mb-3 hpadstep2sumhead">Summary </h4>
        <hr className="mb-3 hpaycreate2line" />

        {tab === 2 ? null : (
          <div className="d-flex mb-4 align-items-center justify-content-between">
            <span className="popin-500-16">Token Name</span>
            <span className="popin-500-16 gold">{formData.summary.name}</span>
          </div>
        )}

        {tab === 2 ? null : (
          <div className="d-flex mb-4 align-items-center justify-content-between">
            <span className="popin-500-16">Token Contract</span>
            <span className="popin-500-16">
              <OverlayTrigger
                key="top"
                placement="top"
                overlay={<Tooltip id="tooltip-top">Copy Token Address</Tooltip>}
              >
                <div
                  role="button"
                  onClick={() => {
                    navigator.clipboard.writeText(formData.summary.token);
                  }}
                >
                  {formData.summary.token ? formData.summary.token.substring(0, 4) : null}...
                  {formData.summary.token ? formData.summary.token.substr(formData.summary.token.length - 4) : null}
                </div>
              </OverlayTrigger>
            </span>
          </div>
        )}
        {tab === 2 ? null : (
          <div className="d-flex mb-4 align-items-center justify-content-between">
            <span className="popin-500-16">Total Supply</span>
            <span className="popin-500-16">{formData.summary.supply}</span>
          </div>
        )}

        {tab === 2 ? null : (
          <div className="d-flex mb-4 align-items-center justify-content-between">
            <span className="popin-500-16">Decimals</span>
            <span className="popin-500-16">{formData.summary.decimals}</span>
          </div>
        )}

        {tab === 2 ? null : (
          <>
            <hr />
            <div className="d-flex my-3 align-items-center justify-content-between">
              <span className="popin-500-16">Sale Type</span>
              <span className="popin-700-16 gold-text">
                {type === "presale" ? "Classic Presale" : <>{isFairLaunch ? "Liquidity Raise" : "Fair Launch"}</>}
              </span>
            </div>
            <hr />
          </>
        )}

        {tab === 2 ? (
          <div className="d-flex mb-4 align-items-center justify-content-between">
            <span className="popin-500-16">Creator</span>
            <span className="popin-500-16">
              <OverlayTrigger
                key="top"
                placement="top"
                overlay={<Tooltip id="tooltip-top">Copy Token Address</Tooltip>}
              >
                <div
                  role="button"
                  onClick={() => {
                    navigator.clipboard.writeText(formData.token.presale_creator);
                  }}
                >
                  {formData.token.presale_creator.substring(0, 4)}...
                  {formData.token.presale_creator.substr(formData.token.presale_creator.length - 4)}
                </div>
              </OverlayTrigger>
            </span>
          </div>
        ) : null}
        {tab === 2 ? (
          <div className="d-flex mb-4 align-items-center justify-content-between">
            <span className="popin-500-16">Balance</span>

            <span className="popin-500-16">
              <NumberFormat displayType="text" thousandSeparator={true} value={formData.summary.balance} />
            </span>
          </div>
        ) : null}
        <div className="d-flex mb-4 align-items-center justify-content-between">
          <span className="popin-500-16">Network</span>
          <div className="popin-500-16">
            <img
              style={{ width: "20px", height: "20px", marginRight: "0.75rem" }}
              src={networkIcon[networks[+network]?.shortName]}
              alt="chain icon"
            />
            {networks[+network]?.shortName}
          </div>
        </div>

        <div className="d-flex mb-4 align-items-center justify-content-between">
          <span className="popin-500-16">Liquidity Asset</span>

          <span className="popin-500-16">{tokenomics?.base_symbol || "Not set"}</span>
        </div>

        <div className="d-flex mb-4 align-items-center justify-content-between">
          <span className="popin-500-16">Presale & Locking Fee</span>

          <span className="popin-500-16">1 BNB</span>
        </div>
        <div className="d-flex mb-3 align-items-center justify-content-between">
          <span className="popin-500-16">Liquidity Raised Fee</span>

          <span className="popin-500-16">1 %</span>
        </div>
        <hr />

        <p className="popin-400-14 grey1-text">
          Please make sure to verify the accuracy of all the information before proceeding
        </p>

        <button
          disabled={!nextButtonValidate || !connected || !validNetwork || pendingTransaction || !noBalance}
          onClick={() => nextTab()}
          className="w-100 text-white btn btn-primary w-100 btn-lg"
        >
          Continue
        </button>
        {!validNetwork && <p className="text-gray text-center text-sm">Please switch to the correct network</p>}
      </div>
    </div>
  );
};

export default Summary;
