import React, { useContext, useCallback, useState } from 'react';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import NetworkContext from '../../../context/network-context';

function EmergencyWithdraw({ emergencyWithdraw, baseSymbol }) {
    const [showConfirm, setShowConfirm] = useState(false);
    const { pendingTransaction, executeTransaction} = useContext(NetworkContext);

    const handleWithdraw = useCallback(async () => {
        const tx = async () => await emergencyWithdraw();
        try {
            await executeTransaction({
                message: 'Executing Transaction',
                tx
            });
        } catch (error) {
            console.log(error);
        }
        setShowConfirm(false);
    }, [emergencyWithdraw, executeTransaction, setShowConfirm]);

    return (<>
    
        <Button
            disabled={pendingTransaction}
            onClick={() => setShowConfirm(true)} variant='link' className="p-0"><span>
                <small>
                    Emergency Withdrawal
                </small>
            </span>
        </Button>
        <Modal
            className="stake-modal"
            aria-labelledby="contained-modal-title-vcenter"
            centered show={!!showConfirm} onHide={() => {
                if (!pendingTransaction) {
                    setShowConfirm(false);
                }
            }} >
            <Modal.Body>
                <Card>
                    <Card.Body>
                        <Row style={{ marginBottom: '1rem' }} >
                            <Col md={12} className=" text-center ">
                                <h3 className="text-white ">Emergency Withdrawal</h3>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: '1rem' }} >
                            <Col md={12} >
                                <p style={{ fontSize: '1rem', fontWeight: 600, textAlign: 'center' }}> <span className='text-primary-gradient'>Tax Warning: </span>A 15% tax is deducted if you withdraw before the end of the sale. The refund will be issued in the form of {baseSymbol} tokens.</p>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: '1rem' }} >
                            <Col md={12} className=" text-center ">
                                <Button onClick={handleWithdraw} >
                                    Withdraw
                                </Button>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Modal.Body>
        </Modal></>
    )
}

export default EmergencyWithdraw;