import React from "react";
import { Col, Row } from "react-bootstrap";
import ReactPlayer from "react-player/youtube";
import { networks } from "web3/web3.js";

export default function IdoHeader({ idoInfo }) {
  return (
    <>
      <div className="idoheaderbackgroundheader">
        <div className="idoheaderbackgroundheadertop">
          <Row>
            <Col md={3} className="mt-5">
              <div className="d-flex w-100">
                <img
                  className="idoImageLogo"
                  loading="lazy"
                  src={`${idoInfo?.info?.imagePath}`}
                  alt="IDO Detail Logo"
                  width="72"
                  height="72"
                />
                <div className="ms-1 d-flex flex-column">
                  <span className="popin-600-22 gold-text">{idoInfo && idoInfo.name}</span>
                  <span className="popin-600-20">${idoInfo && idoInfo.ticker}</span>
                </div>
              </div>
              <div className="mt-3 d-flex">
                {idoInfo ? (
                  <>
                    {(() => {
                      switch (idoInfo?.status) {
                        case 0:
                          return (
                            <>
                              <div className="upper-live">
                                <span className="upper-upcoming-dot"></span>
                                Upcoming
                              </div>
                            </>
                          );
                        case 1:
                          return (
                            <>
                              <div className="upper-live">
                                <span className="upper-live-dot"></span>
                                Live
                              </div>
                            </>
                          );
                        case 2:
                          return (
                            <>
                              <div className="upper-live">
                                <span className="upper-filled-dot"></span>
                                Filled
                              </div>
                            </>
                          );
                        case 3:
                          return (
                            <>
                              <div className="upper-live">
                                <span className="upper-failed-dot"></span>
                                Failed
                              </div>
                            </>
                          );
                        default:
                          return null;
                      }
                    })()}
                  </>
                ) : null}
                {idoInfo.kyc == true && (
                  <div className="d-flex idoheaderbadge me-3">
                    <img className="idoheaderbadgekycicon me-1" loading="lazy" src={"/icons/KYC.svg"} alt="" />
                    <span className="Rubik-500-12">KYC</span>
                  </div>
                )}
                {idoInfo.audit == true && (
                  <div className="d-flex idoheaderbadge me-3">
                    <img className="idoheaderbadgekycicon me-1" loading="lazy" src={"/icons/check.svg"} alt="" />
                    <span className="Rubik-500-12">Audited</span>
                  </div>
                )}
              </div>
              <div className="mt-3 d-flex">
                {idoInfo && (
                  <div className="idoheadernetworkicon d-flex flex-row align-items-center justify-content-center me-4">
                    <img
                      className="idoheadernetworkiconimage me-1"
                      src={`/idoicon/woBG/${networks[idoInfo.chainId].shortName}.svg`}
                      alt="network icons"
                    />
                    <span className="Rubik-600-12">{networks[idoInfo.chainId].shortName}</span>
                  </div>
                )}
                {idoInfo.insurance && (
                  <div className="idoheaderinsuranceicon d-flex flex-row align-items-center justify-content-center">
                    <img
                      className="idoheadernetworkiconimage2 me-1"
                      src={"/icons/shield-kyc.svg"}
                      alt="network icons"
                    />
                    <span className="Rubik-600-12">Insurance</span>
                  </div>
                )}
              </div>
            </Col>
            <Col md={4} className="mt-5">
              <span className="popin-400-16">{idoInfo?.info?.description}</span>
            </Col>
            <Col md={5} className="mt-3 d-flex justify-content-end ">
              <div className="idodetailplayer overflow-hidden">
                {idoInfo?.info?.adsvideourl ? (
                  <ReactPlayer
                    url={idoInfo?.info?.adsvideourl}
                    playing
                    muted
                    className="react-player"
                    width="100%"
                    height="100%"
                  />
                ) : (
                  <ReactPlayer
                    url="https://www.youtube.com/watch?v=-WBTJXOzjd0"
                    playing
                    muted
                    className="react-player"
                    width="100%"
                    height="100%"
                  />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="hpaycreate2line"></div>
      <Row className="idoheaderbackgroundheaderbottom w-100 mx-0">
        <Col md={3} className="d-flex flex-row">
          <span className="popin-600-24 gold-text">
            {idoInfo.type === "fairlaunch" ? <> {idoInfo.isFairlaunch === true ? (<>Liquidity Raise</>) : (<>Fairlaunch</>) }</> : <>Classic Presale</>}
          </span>
        </Col>
        <Col md={4}>
          <div className="d-flex justify-content-start">
            {idoInfo.socials.website ? (
              <a href={idoInfo.socials.website} rel="noopener" target="_blank">
                <img className="me-2" src={"/hpadcreateicon/website.svg"} alt="website" />
              </a>
            ) : null}
            {idoInfo.socials.telegram ? (
              <a href={idoInfo.socials.telegram} rel="noopener" target="_blank">
                <img className="me-2" src={"/hpadcreateicon/telegram.svg"} alt="telegram" />
              </a>
            ) : null}
            {idoInfo.socials.twitter ? (
              <a href={idoInfo.socials.twitter} rel="noopener" target="_blank">
                <img className="me-2" src={"/hpadcreateicon/twitter.svg"} alt="twitter" />
              </a>
            ) : null}
            {idoInfo.socials.instagram ? (
              <a href={idoInfo.socials.instagram} rel="noopener" target="_blank">
                <img className="me-2" src={"/hpadcreateicon/intragram.svg"} alt="intragram" />
              </a>
            ) : null}
            {idoInfo.socials.facebook ? (
              <a href={idoInfo.socials.facebook} rel="noopener" target="_blank">
                <img className="me-2" src={"/hpadcreateicon/facebook.svg"} alt="facebook" />
              </a>
            ) : null}
            {idoInfo.socials.medium ? (
              <a href={idoInfo.socials.medium} rel="noopener" target="_blank">
                <img className="me-2" src={"/hpadcreateicon/medium.svg"} alt="medium" />
              </a>
            ) : null}
            {idoInfo.socials.explorerAddress ? (
              <a href={idoInfo.socials.explorerAddress} rel="noopener" target="_blank">
                <img className="me-2" src={"/hpadcreateicon/explorerlink.svg"} alt="explorerlink" />
              </a>
            ) : null}
          </div>
        </Col>
      </Row>
    </>
  );
}
