import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

import timer from "../../../static/time.svg";

import NetworkContext from "../../context/network-context";
import { addChain } from "../../web3/web3";
import NetworkPicker from "./NetworkPicker";

const Terms = ({ getValue, onNext, onSetNetwork, yesToFairLaunch, noToFairLaunch, isFairLaunch }) => {
  const [position, setPosition] = useState(false);
  const [branch, setBranch] = useState("presale");
  const [isValidNetwork, setIsValidNetwork] = useState(false);
  const { connected, network } = useContext(NetworkContext);

  // The scroll listener
  useEffect(() => {
    const fn = async () => {
      onNext(1, position, "termsandconditions", { branch });
    };
    fn();
  }, [position, branch]);

  const nextTab = () => {
    getValue();
  };

  const setFuncPresale = () => {
    setBranch("presale");
  };
  const setFuncFairlaunch = () => {
    setBranch("fairlaunch");
  };
  const onChangeCheckbox = ({ target: { checked } }) => setPosition(checked);

  return (
    <Row className="mb-2 justify-content-center">
      <Col md={12} lg={9} className="mb-md-5 mb-4">
        <div className="d-flex justify-content-center btn-presalesect mb-5">
          <button
            onClick={() => {
              setFuncPresale();
              noToFairLaunch();
            }}
            className={`mx-3 selecthpadbtn ${branch === "presale" ? "active" : null}`}
          >
            <img src={timer} alt="timer" />
            <span className="mb-0 mt-2">Presale</span>
          </button>
          <button
            onClick={() => {
              setFuncFairlaunch();
              noToFairLaunch();
            }}
            className={`mx-3 selecthpadbtn ${branch === "fairlaunch" && isFairLaunch === false && "active"}`}
          >
            <img width="83" src={"/Fairlaunch.svg"} alt="timer" />
            <span className="mb-0 mt-2">Fair Launch</span>
          </button>
          <button
            onClick={() => {
              setFuncFairlaunch();
              yesToFairLaunch();
            }}
            className={`mx-3 selecthpadbtn ${branch === "fairlaunch" && isFairLaunch === true && "active"}`}
          >
            <img src={"/liquidraise.svg"} alt="liquidraise" />
            <span className="mb-0 mt-2">Liquidity Raise</span>
          </button>
        </div>

        <div className="text-center mb-5">
          <NetworkPicker
            network={network}
            onChangeNetwork={value => {
              onSetNetwork(value);
              if (!value) {
                return;
              }
              addChain(value.chainId);
            }}
            onToggleValidNetwork={bool => setIsValidNetwork(bool)}
          />
        </div>

        <div className="terms-outer">
          <div className="terms-header d-flex align-items-center justify-content-between">
            <div className="hpad-brand-holder">
              <img src={"/HPADterms.svg"} width="102" height="26" alt="HPAD LOGO" />
            </div>
            <div className="ms-5">
              <p className="text-center hpad-brand-desc">
                HPAD is a secure launchpad that care, value and protect the growth of the project No token are taken as
                fee. HPAD only utilizes native token fee schedules
              </p>
            </div>
          </div>
          <div className="gadientline-hpad"></div>
          <div className="terms-area">
            <Row className="gx-3 rowconditional d-flex justify-content-between me-1">
              <Col md={12} lg={2} className="">
                <span className="mt-3 presalecondition1">Three Types of Presale</span>
              </Col>
              <Col md={12} lg={3} className="presaleconitionbox">
                <p className="presaleconitiontext">
                  A <b className="text-white">presale</b> is an idea of token scarcity at a low market price. This
                  framework makes the whole event rather exclusive, helping the team create hype on a new project.
                </p>
              </Col>
              <Col md={12} lg={3} className="presaleconitionbox">
                <p className="presaleconitiontext">
                  A <strong className="text-white">fair launch</strong> is a special form of liquidity raise that
                  removes team incentives by requiring the total token supply to be allocated to the raise. A fair
                  launch aims to prevent project from distributing tokens before the general public can access the
                  platform.
                </p>
              </Col>
              <Col md={12} lg={3} className="presaleconitionbox">
                <p className="presaleconitiontext">
                  A <strong className="text-white">liquidity raise</strong> aims to gather the capital necessary for deploying
                  the tokens of a project to the market. In contrast to the "presale", a liquidity raise distributes
                  tokens based on the investors relative contribution to the total capital that was raised, and does not
                  impose any hard cap or limits on how much capitals investors can deploy.
                </p>
              </Col>
            </Row>
            <h6 className="mt-4 popin-600-25">Service-Specific Terms</h6>

            <big className="popin-500-16">Introduction</big>
            <p>
              These HPAD Service-Specific Terms (“SSTs”) only apply if you have purchased a service offered by HedgePay
              Sdn Bhd (“HedgePay”) incorporating any of our HPAD features ("HPAD”). These SSTs are “Additional Terms” as
              defined in the HedgePay Terms of Service (“ToS”). Capitalized terms not defined in these SSTs have the
              meaning provided in the ToS, and to the extent any conflict exists these SSTs should prevail.
            </p>

            <big className="popin-500-16">Service Description</big>
            <p>
              HPAD facilitates early-stage investors to create and list tokens, raise capital, and launch IGO project
              in a safe blockchain ecosystem. Your HPAD purchase will be a subscription to our services offered under
              HPAD and the discontinuation of the use of services will render the Contract for Service (“Contract”)
              between you and HedgePay terminated, unless otherwise specified.
            </p>

            <big className="popin-500-16">Intellectual Property</big>
            <p>
              These SSTs do not grant or transfer to you any ownership rights to any intellectual property used in
              developing HPAD. You will be granted permission to use Copyrighted Work/Intellectual property of HedgePay
              for the designated functions under HPAD. Our permission granted to you with respect to such Copyrighted
              Work/Intellectual Property is subject to the guidelines specified in ToS and is only valid for the period
              the Contract is in effect.
            </p>

            <big className="popin-500-16">Termination of Services</big>
            <p className="conditionaltermstasd">
              Upon termination of the use of services, you will immediately cease all use of features and functions
              offered by HPAD and further use of Copyrighted Work/Intellectual Property will impose legal implications
              on you.
            </p>
            <ul>
              <li className="conditionaltermstasd">
                Termination by you - You may terminate the Contract by unsubscribing from our services, subject to ToS
                of HedgePay.
              </li>
              <li className="conditionaltermstasd">
                Termination by HedgePay - We are within our rights to terminate the Contract and suspend the services
                offered, subject to ToS of HedgePay.
              </li>
            </ul>

            <big className="popin-500-16">Changes to Pricing</big>
            <p>
            We may change our pricing for the services offered under HPAD from time to time and such changes will be notified to you via Direct Communication. Those changes to pricing will have no effect on the features and functions that are already purchased by you prior to such changes and the prices of the others may be subject to change.
            </p>

            <big className="popin-500-16">Warranty Disclaimer</big>
            <p>
            Except as expressly provided in these SSTs and to the extent permitted by applicable law, HPAD, its content, and any services found or accessed through this website are provided on an “as is” or “as available” basis, and HedgePay does not make warranties of any kind, express, implied, or statutory, including, but not limited to, those of merchantability, fitness for a particular purpose, and non-infringement.
            </p>

            <div className="d-flex justify-content-center" style={{ alignItems: "center" }}>
              <input className="mx-2" type={"checkbox"} onChange={onChangeCheckbox} />
              <p className="d-flex" style={{ alignItems: "center", lineHeight: 1 }}>
                I have read and agree with the terms and conditions
              </p>
            </div>
          </div>
        </div>

        <button
          type="button"
          disabled={!position || !connected || !isValidNetwork}
          checked={position}
          onClick={() => nextTab()}
          className="mt-4 w-100 text-white btn btn-primary mb-4 w-100 btn-lg"
        >
          Create
        </button>
      </Col>
    </Row>
  );
};

export default Terms;
